.activity-container {
  background: #18130d80 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  padding: 30px 60px;

  h1 {
    font-family: "Bai Jamjuree";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffdd8b;
    text-transform: uppercase;
  }

  h4 {
    margin: 38px 0 0 0 !important;

    font-family: "Bai Jamjuree";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .scroll-container {
    width: 100%;

    .activity-table {
      width: 760px;
      margin: 20px 0 0 0;

      .table-header {
        background: #38fea84d 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
        padding: 8px 15px;

        ul {
          display: flex;
          justify-content: space-around;
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            width: 100%;
            font-family: "Bai Jamjuree";
            font-size: 12px;
            letter-spacing: 0.6px;
            color: #ffffff;
          }
        }
      }

      .table-content {
        background: transparent
          linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #377b5e;
        border-radius: 0px 0px 8px 8px;
        border-top: 0;

        ul {
          display: flex;
          justify-content: space-around;
          padding: 8px 15px;
          margin: 0;

          li {
            list-style: none;
            width: 100%;
            font-family: "Bai Jamjuree";
            font-size: 12px;
            letter-spacing: 0.6px;
            color: #ffffff;
          }
        }

        .ul-odd {
          background: #38fea826 0% 0% no-repeat padding-box;
        }
      }
    }
  }
}

@media (max-width: 1160px) {
  .activity-container {
    padding: 20px;
  }
}

@media (max-width: 1067px) {
  .activity-container {
    .scroll-container {
      overflow-x: scroll;
    }
  }
}
