.item-characteristics {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 13px 0 0 0;
  flex-wrap: wrap;
  padding: 0 10px;
  justify-content: center;

  .item-genre {
    img {
      width: 34px;
    }
  }
  .item-type {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background: transparent linear-gradient(180deg, #141f1a 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #377b5e;
    width: 34px;
    height: 34px;

    img {
      width: 20px;
    }
  }

  .item-archetype {
    border-radius: 4px;
    height: 29px;
    padding: 0 10px;
    background: transparent linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #377b5e;

    span {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: #37f5a2;
    }
  }

  .berserker {
    border: 1px solid #ff756b;
    background: transparent linear-gradient(180deg, #750015 0%, #de392b 100%) 0%
      0% no-repeat padding-box;

    span {
      color: #ff938b;
    }
  }

  .wasteller {
    background: transparent linear-gradient(180deg, #640c67 0%, #e32ae7 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #fe97ff;

    span {
      color: #fe97ff;
    }
  }

  .alchemist {
    background: transparent linear-gradient(180deg, #004c68 0%, #4bcfff 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #39ffff;

    span {
      color: #39ffff;
    }
  }

  .izarian {
    background: transparent linear-gradient(180deg, #6e3c00 0%, #c79200 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #ffd264;

    span {
      color: #ffd264;
    }
  }

  .soulhunter {
    background: transparent linear-gradient(180deg, #230d4a 0%, #824bb2 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #c685ff;

    span {
      color: #c685ff;
    }
  }

  .item-rarity {
    background: transparent linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #377b5e;
    border-radius: 4px;
    height: 29px;
    padding: 0 15px;

    span {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: #37f5a2;
    }
  }
}

@media (max-width: 800px) {
  .item-characteristics {
    justify-content: center;
  }
}
