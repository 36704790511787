.img-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 115px 0 0 0;
  background: url("../../../../public/assets/images/marketplace/backgrounds/marketplace-footer-img.jpg");
  background-position: 0 85%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 508px;
  background-position: top;

  h2 {
    text-align: center;
    font-family: "Orbitron";
    font-weight: bold;
    font-size: 42px;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
  }

  p {
    text-align: center;
    font-family: "Bai Jamjuree";
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 0 20px;
    margin: 34px 0 0 0;
    max-width: 668px;
  }

  button {
    margin: 20px 0 0 0;
    width: 363px;
    height: 110px;
    font-family: "Bai Jamjuree";
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0px;
    color: #131b17;
    text-transform: uppercase;
  }
}

.text-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent linear-gradient(180deg, #201a14 0%, #443928 100%) 0%
    0% no-repeat padding-box;

  .footer-links {
    display: flex;
    flex-direction: column;
    padding: 78px 0 0 0;

    ul {
      display: flex !important;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
      padding: 0 5px;
    }

    li {
      list-style: none;
      text-align: left;
      font: normal normal 600 16px/20px Bai Jamjuree;
      letter-spacing: 0.8px;
      color: #ffffff;
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .footer-logo {
    margin: 42px 0 0 0;
    max-width: 188px;
  }

  hr {
    border-color: #6b6152;
    width: 100%;
    margin: 40px 0 20px 0;
  }

  .sub-footer {
    max-width: 1920px;
    width: 100%;
    padding: 0 30px 28px 30px;
    display: flex;
    justify-content: space-between;

    p {
      text-align: center;
      font: normal normal normal 16px/24px Bai Jamjuree;
      letter-spacing: 0px;
      color: #ffffff;
      margin: 0;
    }

    ul {
      display: flex;
      gap: 34px;
      margin: 0;

      li {
        list-style: none;
        display: flex !important;
        align-items: center;
        align-content: center;
        justify-content: center;

        a {
          background: none;
          border: none;
          width: 25px;
          height: 23px;
        }
      }
    }

    .telegram {
      background-image: url("../../../../public/assets/images/icons/telegram-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .youtube {
      background-image: url("../../../../public/assets/images/icons/youtube-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 30px;
    }

    .twitter {
      background-image: url("../../../../public/assets/images/icons/twitter-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .github {
      background-image: url("../../../../public/assets/images/icons/git-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

@media (max-width: 990px) {
  .text-footer {
    .sub-footer {
      padding: 0 20px 20px 20px;
    }
  }
}

@media (max-width: 645px) {
  .text-footer {
    .sub-footer {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
}
