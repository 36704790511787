.password-strength-container {
  margin: 5px 0 0 0;
  max-width: 464px;

  span {
    display: block;
    text-align: center;
    width: 100%;
    margin: 3px 0 0 0;

    font-family: "Bai Jamjuree";
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #39ffa9;
  }
}

.password-strength {
  background: #39ffa946 0% 0% no-repeat padding-box;
  border-radius: 3px;
  width: 100%;

  height: 7px;

  .power-point {
    background: #39ffa9 0% 0% no-repeat padding-box;
    border-radius: 3px;
    width: 1%;
    height: 7px;
    transition: all ease-in-out 0.3s;
  }
}
