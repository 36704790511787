.feature01 {
  background-image: var(--feature01-desktop-img);
}
.feature02 {
  background-image: var(--feature02-desktop-img);
}
.feature03 {
  background-image: var(--feature03-desktop-img);
}
.feature04 {
  background-image: var(--feature04-desktop-img);
}

.feature {
  background-repeat: no-repeat;
  background-size: contain;
  width: 343px;
  height: 477px;

  .feature-content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    h2,
    p {
      position: absolute;
    }

    h2 {
      top: 64%;
      max-width: 247px;
      text-align: center;
      font-family: "Bai Jamjuree";
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.1px;
      color: #ffdd8b;
      text-transform: uppercase;
    }

    p {
      top: 74%;
      text-align: center;
      font-family: "Bai Jamjuree";
      font-size: 16px;
      letter-spacing: 0px;
      color: #ffffff;
      max-width: 300px;
    }
  }
}

@media (max-width: 1443px) {
  .feature {
    width: 320px;

    .feature-content {
      h2 {
        top: 59%;
      }

      p {
        top: 68%;
        max-width: 280px;
      }
    }
  }
}

@media (max-width: 1351px) {
  .feature {
    width: 300px;

    .feature-content {
      h2 {
        top: 55%;
      }

      p {
        top: 63%;
        max-width: 260px;
      }
    }
  }
}

@media (max-width: 712px) {
  .feature01 {
    background-image: var(--feature01-mobile-img);
  }
  .feature02 {
    background-image: var(--feature02-mobile-img);
  }
  .feature03 {
    background-image: var(--feature03-mobile-img);
  }
  .feature04 {
    background-image: var(--feature04-mobile-img);
  }

  .feature {
    height: 336px;
    width: 343px;
    .feature-content {
      h2 {
        top: 52%;
      }

      p {
        top: 66%;
      }
    }
  }
}
