header {
  background: transparent linear-gradient(180deg, #201a14 0%, #443928 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  justify-content: center;
}

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  gap: 45px;
  max-height: 80px;

  .download-icon {
    border: 0;
    background: transparent;
    display: none;
  }

  .mobile-side-menu-header {
    display: none;
  }

  .menu-icon {
    border: 0;
    display: none;
    background: transparent;

    img {
      width: 28px;
      height: 24px;
    }
  }

  .logo {
    padding: 20px 0 20px 53px;
    width: 185px;
  }

  .navbar-items {
    align-items: center;
    background-image: url("../../../public/assets/images/backgrounds/navbar-background.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex !important;
    gap: 30px;
    padding: 13px 30px;
    border-radius: 20px;

    .button-active {
      color: #39ffa9 !important;
    }

    a,
    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      font-family: "Bai Jamjuree";
      font-weight: 400;
      letter-spacing: 0.8px;
      font-size: 16px;
      color: #ffdd8b;
      text-transform: uppercase;
      text-decoration: none;
      line-height: revert;
    }
  }

  .right-items {
    display: flex !important;
    gap: 22px;
    position: absolute;
    right: 50px;
    top: 23%;

    .button-brown {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: url("../../../public/assets/images/backgrounds/menu-item-single.svg")
        no-repeat;
      background-size: contain;
      background-position: center;
      width: 177px;
      height: 46px;

      font-family: "Bai Jamjuree";
      letter-spacing: 0.8px;
      color: #ffdd8b;
      text-transform: uppercase;

      .user-icon {
        width: 17px;
        height: 17px;
        margin: 0 10px 0 0;
      }

      .arrow-icon {
        width: 8px;
        height: 9px;
        margin: 0 0 0 5px;
      }
    }

    .header-playnow-btn {
      width: 171px;
      height: 43px;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .mobile-side-menu {
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }
}

.arrow-icon {
  width: 8px;
  height: 9px;
  margin: -4px 0 0 0;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .dropdown-container-items {
    background: url("../../../public/assets/images/backgrounds/dropdown.svg")
      no-repeat;
    background-size: contain;
    width: 188px;
    height: 188px;
    position: absolute;
    top: 40px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    ul {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        list-style: none;
        text-align: left;
        font-family: "bai jamjuree";
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #39ffa9 !important;
        text-transform: uppercase;
        margin: 0 20px 0 0;
        cursor: pointer;

        a {
          color: #39ffa9 !important;
          width: 100%;
          display: block;
          height: 100%;
          padding: 0 0 0 5px;
        }

        &:hover > a {
          background-color: #39ffa9;
          color: #000 !important;
        }
      }
    }

    .header-login-btn {
      align-self: center;
      width: 150px;
      height: 36px;
      font-weight: bold;
      font-size: 17px;
      letter-spacing: 0.85px;
      margin: 0 0 10px 0;
      background-size: auto !important;
    }
  }

  .account-dropdown {
    margin: 11px 0 0 0;

    ul > li {
      display: flex;
      gap: 10px;
      align-items: center;

      img {
        width: 12px !important;
        height: 12px !important;
      }
    }

    li:first-child {
      text-decoration: underline;
    }
    ul > li:hover {
      background-color: transparent;
      color: #39ffa9;
    }
  }
}

@media (max-width: 1420px) {
  .navbar {
    gap: 20px;

    .logo {
      padding: 20px 0 20px 0;
      width: 120px;
    }

    .right-items {
      gap: 10px;
      right: 5px;
      top: 22%;
    }
  }
}

@media (max-width: 1280px) {
}

@media (max-width: 1250px) {
  .navbar {
    .logo {
      width: 60%;
    }

    .navbar-items {
      margin: 0 0 0 -70px;

      button,
      a {
        font-size: 12px;
      }
    }

    .right-items {
      top: 22%;

      .header-playnow-btn {
        width: 145px;
        font-size: 15px;
      }

      .button-brown {
        font-size: 12px;
        width: 140px;

        .user-icon {
          width: 13px;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .navbar {
    .logo {
      width: 55%;
    }
    .navbar-items {
      gap: 10px;
    }

    .right-items {
      .lang-icon {
        width: 15px;
      }

      .header-playnow-btn {
        width: 135px;
      }

      .button-brown {
        width: 125px;

        .user-icon {
          width: 12px;
        }
      }
    }
  }
}

@media (max-width: 955px) {
  .navbar {
    .logo {
      width: 45%;
    }
    .navbar-items {
      margin: 0 0 0 -110px;
      padding: 15px;
    }
  }
}

@media (max-width: 900px) {
  .show-menu {
    margin: 0 !important;
    box-shadow: 200px 0 #b8b8b8;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .logo {
      width: 140px;
    }

    .download-icon {
      display: flex;
    }

    .menu-icon {
      display: flex;
    }

    .navbar-items {
      margin: 70px 0 0 35px;
      flex-direction: column;
      align-items: start;
      background: none;

      .button-active {
        text-align: left !important;
      }
    }

    .mobile-side-menu {
      margin-left: -100vw;

      background: #0f0c08 0% 0% no-repeat padding-box;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 80%;

      .mobile-side-menu-header {
        background: transparent
          linear-gradient(180deg, #201a14 0%, #443928 100%) 0% 0% no-repeat
          padding-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;

        .close-icon {
          border: 0;
          background: transparent;
          margin: 0 0 0 21px;
        }
      }
    }

    .right-items {
      position: relative;
      flex-direction: column;
      align-items: self-start;
      top: 0;
      margin: 0 0 0 55px;

      .lang-icon {
        position: fixed;
        bottom: 5vh;
      }

      .dropdown-container {
        position: fixed;
        top: 105px;
      }

      .header-playnow-btn {
        top: 20px;
        left: -2%;
        position: absolute;
      }
    }
  }

  .dropdown-container {
    .dropdown-container-items {
      margin: 2px 0 0 0;
    }
  }

  .active {
    text-align: left !important;
  }
}

@media (max-width: 320px) {
  .navbar {
    padding: 0 5px;
    .mobile-side-menu {
      width: 100%;
    }
  }
}
