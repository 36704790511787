.header {
  display: flex;
  gap: 15px;

  .category-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 46px;
    background: #5e523b4d 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    padding: 0 15px;

    h1 {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 1px;
      color: #443928;
      text-transform: uppercase;
    }

    span {
      font-family: "Bai Jamjuree";
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.8px;
      color: #443928;
      text-transform: uppercase;
    }
  }

  .right-items {
    display: flex;
    gap: 15px;

    .filters {
      min-width: 222px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: #5e523b 0% 0% no-repeat padding-box;
      border: 1px solid #443928;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 15px;
      padding: 5px 15px;

      .filters-text {
        font-family: "Bai Jamjuree";
        font-size: 14px;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: none;
      }

      .arrow {
        width: 11px;
        margin: 0 0 0 10px;
        transition: all ease-in-out 0.3s;
      }

      .arrow-open {
        transform: rotate(180deg);
        transition: all ease-in-out 0.3s;
      }

      .filters-dropdown {
        display: flex;
        z-index: 1;
        justify-content: center;
        position: absolute;
        min-width: 100%;
        background: #5b4f36 0% 0% no-repeat padding-box;
        border: 1px solid #77694b;
        right: 0;
        top: 100%;
        border-radius: 4px;
        padding: 10px;
        margin: 10px 0 0 0;

        ul {
          padding: 0;
          margin: 0;
          width: 100%;

          li {
            cursor: pointer;
            text-align: center;
            list-style: none;
            font-family: "Bai Jamjuree";
            font-weight: normal;
            font-size: 14px;
            letter-spacing: 0.7px;
            color: #ffffff;
            border-radius: 4px;
            padding: 5px;
          }

          li:hover {
            background: #423926 0% 0% no-repeat padding-box;
          }
        }
      }
    }

    .select-view-container {
      display: flex;

      .select-view-item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        background: #5e523b 0% 0% no-repeat padding-box;
        border: 1px solid #443928;
        border-radius: 4px;
        height: 46px;
        width: 46px;
        padding: 5px 10px;

        img {
          width: 14px;
        }

        .list {
          width: 20px;
        }
      }

      .active {
        background: #5e523b4d 0% 0% no-repeat padding-box;
      }
    }
  }
}

.content > div {
  gap: 20px;
  display: flex;
  justify-content: center;
}

.content {
  gap: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 26px 0 0 0;
  min-height: 150px;

  div {
    h1 {
      font-size: 25px;
    }
  }
}

.load-more {
  margin: 20px 0 40px 0;
  align-self: center;
  background-color: transparent;
  border: none;
  width: 100%;
  max-width: 226px;
  min-height: 48px;

  text-transform: uppercase;
  font-family: "Bai Jamjuree";
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: #443928;
}

@media (max-width: 901px) {
  .header {
    .right-items {
      .filters {
        min-width: 180px;
      }
    }
  }
}

@media (max-width: 870px) {
  .header {
    flex-direction: column;

    .right-items {
      justify-content: space-between;
    }
  }
}

@media (max-width: 783px) {
  .header {
    padding: 0 10px;
    margin: 0 0 20px 0;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 0 10px;
  }
}

@media (max-width: 500px) {
  .content {
    padding: 20px;
  }
}

@media (max-width: 350px) {
  .header {
    .right-items {
      .filters {
        min-width: 0;
      }
    }
  }
}
