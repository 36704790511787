.overview {
  display: flex;
  gap: 20px;

  .overview-content {
    display: flex;
    flex-direction: column;
    gap: 11px;
    width: 100%;
    max-width: 706px;

    .account-overview,
    .wallet {
      position: relative;
      background: transparent linear-gradient(185deg, #131b17 0%, #1e533e 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #377b5e;
      border-radius: 8px;
      width: 100%;
      padding: 29px 36px;
    }

    .account-overview {
      height: 340px;

      .edit {
        position: absolute;
        top: 27px;
        right: 20px;
        background: #37f6a359 0% 0% no-repeat padding-box;
        border: none;

        font-family: "Bai Jamjuree";
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.2px;
        color: #ffffff;
        text-transform: uppercase;
        padding: 5px 15px;
        border-radius: 4px;
      }

      h4 {
        font-family: "Bai Jamjuree";
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 1px;
        color: #ffffff;
        text-transform: uppercase;
      }

      .joined {
        text-decoration: underline;
        font-family: "Bai Jamjuree";
        font-size: 16px;
        letter-spacing: 0.8px;
        color: #37f8a4;
        margin: 9px 0 0 0;
      }

      .user-info {
        display: flex;
        margin: 44px 0 0 0;
        align-items: center;
        gap: 28px;

        .user-icon-container {
          width: 103px;
          height: 103px;
          background: #39ffa973 0% 0% no-repeat padding-box;
          border-radius: 100%;
          display: flex;
          justify-content: center;

          img {
            width: 54px;
          }
        }

        .user-info-content {
          p {
            font-family: "Bai Jamjuree";
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.9px;
            color: #ffffff;
            margin: 0;
          }

          span {
            text-decoration: underline;
            font-family: "Bai Jamjuree";
            font-size: 18px;
            letter-spacing: 0.9px;
            color: #37f8a4;
          }
        }
      }

      .address {
        cursor: pointer;
        background: #37f8a442 0% 0% no-repeat padding-box;
        border: 1px solid #377b5e;
        border-radius: 4px;
        max-width: fit-content;
        word-break: break-all;
        padding: 5px 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        margin: 20px 0 0 0;
        img {
          width: 13px;
          height: 15px;
        }

        span {
          font-size: 14px;
          font-family: "Bai Jamjuree";
          letter-spacing: 0.7px;
          color: #37f8a4;

          a {
            text-decoration: none;
            color: #ffffff;
          }
        }
      }
    }

    .wallet {
      height: 100%;

      .buy-arker {
        position: absolute;
        top: 23px;
        right: 18px;
        background-size: cover;
        width: 140px;
        height: 33px;

        font-family: "Bai Jamjuree";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.7px;
        color: #131b17;
        text-transform: uppercase;
      }

      h4 {
        font-family: "Bai Jamjuree";
        font-weight: 600;
        font-size: 22px;
        letter-spacing: 1.1px;
        color: #37f8a4;
        text-transform: uppercase;
      }

      .balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        margin: 24px 0 0 0;

        img {
          width: 35px;
        }

        .arker-qty {
          font-family: "Bai Jamjuree";
          font-size: 28px;
          font-weight: 600;
          letter-spacing: 1.4px;
          color: #ffffff;
          text-transform: uppercase;
        }

        .arker-price {
          font-family: "Bai Jamjuree";
          font-size: 18px;

          letter-spacing: 0px;
          color: #37f8a4;
        }
      }

      .table-title {
        margin: 68px 0 0 0;
        display: flex;
        justify-content: space-between;

        span {
          font-family: "Bai Jamjuree";
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.8px;
          color: #37f8a4;
          text-transform: uppercase;
        }

        .see-all-button {
          text-decoration: underline;
          font-family: "Bai Jamjuree";
          font-size: 14px;
          letter-spacing: 0.7px;
          color: #37f6a3;
          border: 0;
          background: none;
        }
      }
    }
  }
}

@media (max-width: 1355px) {
  .overview {
    .overview-content {
      .wallet {
        .balance {
          .arker-qty {
            font-size: 22px;
          }
        }
      }
    }
  }
}
@media (max-width: 1087px) {
  .overview {
    flex-direction: column;

    .overview-content {
      max-width: 100%;
    }
  }
}

@media (max-width: 450px) {
  .overview {
    .overview-content {
      .account-overview {
        height: auto;
        padding: 29px 20px 20px 20px;

        .user-info {
          flex-direction: column;
          margin: 20px 0 0 0;
          gap: 15px;

          .user-info-content {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }

      .wallet {
        .balance {
          img {
            width: 30px;
          }
          .arker-qty {
            font-size: 18px;
          }

          .arker-price {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 365px) {
  .overview {
    .overview-content {
      .account-overview,
      .wallet {
        padding: 15px;

        .buy-arker {
          top: 10px;
          right: 10px;
        }

        .edit {
          top: 13px;
          right: 10px;
        }
      }
    }
  }
}

@media (max-width: 351px) {
  .overview {
    .overview-content {
      .account-overview {
        h4 {
          font-size: 18px;
        }

        .joined {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 329px) {
  .overview {
    .overview-content {
      .account-overview {
        h4 {
          font-size: 15px;
        }
        .joined {
          font-size: 12px;
        }
      }
    }
  }
}
