.marketplace-egg-info-container {
  max-width: 1432px;
  margin: auto;
  padding: 0 20px;

  .breadcrumbs {
    display: flex;
    align-items: center;
    background: #ffdd8b33 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 0 17px;
    height: 30px;

    span {
      font-family: "Bai Jamjuree";
      font-size: 14px;
      letter-spacing: 0.7px;
      color: #ffdd8b;
    }
  }

  .top {
    margin: 10px 0 0 0;
    display: flex;
    gap: 20px;

    .image-container,
    .egg-info {
      background: #0f0c0899 0% 0% no-repeat padding-box;
      border: 1px solid #6b6152;
      border-radius: 6px;
    }

    .image-container {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 600px;
      max-width: 826px;

      .wishlist-icon,
      .fullscreen-icon {
        position: absolute;
        top: 15px;
        width: 45px;
        cursor: pointer;
      }
      .wishlist-icon {
        left: 15px;
        z-index: 1;
      }

      .fullscreen-icon {
        right: 15px;
        z-index: 1;
      }

      .egg-image-container {
        position: relative;
        background-image: url("../../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .egg-image {
          height: 300px;
          width: auto;
        }
      }

      .for-sale {
        position: absolute;
        width: auto;
        height: 90%;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .egg-info {
      position: relative;
      width: 100%;
      max-width: 585px;
      height: 600px;
      padding: 30px 49px;

      h1 {
        font-family: "Bai Jamjuree";
        font-weight: bold;
        font-size: 26px;
        letter-spacing: 1.3px;
        color: #ffffff;
        text-transform: uppercase;
      }

      .available {
        margin: 20px 0 0 0;
        display: block;
        background: #ffdd8b1a 0% 0% no-repeat padding-box;
        border-radius: 4px;
        width: 100%;
        text-align: center;
        height: 39px;
        line-height: 40px;
        font-family: "Bai Jamjuree";
        font-size: 16px;
        letter-spacing: 0.8px;
        color: #ffdd8b99;
      }

      .current-price {
        margin: 70px 0 0 0;

        span {
          font-family: "Bai Jamjuree";
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0px;
          color: #ffffff80;
        }

        .price {
          display: flex;
          gap: 15px;
          img {
            width: 23.4px;
          }

          span {
            font-family: "Bai Jamjuree";
            font-weight: bold;
            font-size: 26px;
            line-height: 33px;
            letter-spacing: 2.6px;
            color: #ffffff;
            text-transform: uppercase;
          }
        }
      }

      .buttons-container {
        margin: 36px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          border: 0;
          background-color: transparent;
        }

        .buy-button {
          width: 345px;
          height: 110px;
          cursor: pointer;
        }

        .connect-wallet-button {
          width: 345px;
          height: 110px;
          cursor: pointer;
          font-size: 18px;
        }

        .disabled:hover {
          background-image: url("../../../../../public/assets/images/buttons/button-green.png");
          cursor: not-allowed;
        }

        .add-wishlist-button {
          width: 290px;
          height: 70px;
          font-family: "Bai Jamjuree";
          font-size: 18px;
          line-height: 23px;
          font-weight: 600;
          letter-spacing: 0.9px;
          color: #ffdd8b;
          text-transform: uppercase;
        }

        .inventory-buttons-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          align-content: center;

          .sell-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 8px;
            padding: 30px 0 20px 0;
            margin: 0 0 20px 0;
            border: 1px solid #ffdd8b80;

            input {
              border: 1px solid #6b6152;
              border-radius: 8px;
              height: 54px;
              padding: 0 10px 0 20px;
              color: #fff;
              background-color: #19150f;
              min-width: 232px;
              color-scheme: dark;
              width: 80%;

              &:focus {
                outline: 2px solid #6b6152;
              }
            }

            .amount-input {
              padding: 0 10px 0 45px;
              background-color: #0f0c08;
              background: url("../../../../../public/assets/images/icons/arker-icon.svg")
                no-repeat scroll 15px;
            }
          }
        }
      }

      .owned {
        position: absolute;
        bottom: 30px;
        left: 49px;
        margin: 0;
        font-family: "Bai Jamjuree";
        font-size: 16px;
        letter-spacing: 0px;
        color: #ffffff;

        a {
          color: #38fea8;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .bottom {
    margin: 25px 0 36px 0;
    display: flex;
    gap: 20px;

    .egg-description-container {
      width: 100%;
      max-width: 826px;
      height: fit-content;

      .egg-description,
      .egg-activity {
        background: transparent
          linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #377b5e;
        border-radius: 8px;

        .description-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #38fea84d 0% 0% no-repeat padding-box;
          border-radius: 8px 8px 0px 0px;
          width: 100%;
          height: 43px;
          padding: 0 20px;
          cursor: pointer;

          span {
            font-family: "Bai Jamjuree";
            font-weight: 600;
            line-height: 40px;
            font-size: 20px;
            letter-spacing: 1px;
            color: #38fea8;
            text-transform: uppercase;
          }

          img {
            width: 15px;
            transition: all ease-in-out 0.3s;
          }

          .rotate {
            transform: rotate(180deg);
            transition: all ease-in-out 0.3s;
          }
        }

        .description-content {
          display: flex;
          justify-content: space-evenly;
          max-height: 0;
          overflow: hidden;
          transition: all ease-out 0.3s;

          .info {
            /* width: 100%; */
            min-width: 267px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info-egg {
              display: flex;
              width: 100%;

              .info-egg-name,
              .info-egg-value {
                width: 50%;
                font-family: "Bai Jamjuree";
                font-size: 14px;
                line-height: 26px;
                letter-spacing: 0.7px;
                color: #38fea8;
                text-transform: uppercase;
                margin: 0;
              }

              .info-egg-name {
                text-align: end;
              }
              .info-egg-value {
                color: #fff;
                margin: 0 0 0 15px;
              }
            }
          }

          .description-text {
            max-width: 391px;
            display: flex;
            align-items: center;

            p {
              font-family: "Bai Jamjuree";
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0px;
              color: #ffffff;
            }
          }
        }

        .description-content-show {
          padding: 35px 0 20px 0;
          max-height: 1500px;
          transition: all ease-in 0.3s;
        }

        .stats {
          display: flex;
          column-gap: 35px;
          flex-wrap: wrap;
          padding: 0 20px 0 20px;
          max-height: 0;
          overflow: hidden;
          transition: all ease-out 0.3s;

          .attack,
          .life,
          .power,
          .agility,
          .energy {
            display: flex;
            margin: 7px 0 0 0;
            gap: 9px;

            span {
              font-family: "Bai Jamjuree";
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0px;
              color: #ffffff;
              min-width: 85px;
            }
          }
        }

        .stats-show {
          padding: 13px 20px 25px 20px;
          max-height: 1000px;
          transition: all ease-in 0.3s;
        }
      }

      .egg-activity {
        .egg-activity-content {
          height: 0;
          overflow: scroll;
          transition: all ease-out 0.3s;
          display: flex;
          flex-direction: column;
          -ms-overflow-style: none;
          scrollbar-width: none;

          .egg-activity-table {
            thead {
              tr {
                th {
                  padding: 5px 15px;
                  list-style: none;
                  font-family: "Bai Jamjuree";
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 26px;
                  letter-spacing: 0.7px;
                  color: #38fea8;
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 5px 15px;
                  font-family: "Bai Jamjuree";
                  font-size: 14px;
                  line-height: 18px;
                  letter-spacing: 0.7px;
                  color: #ffffff;
                }
              }
            }
          }

          ul {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            margin: 0;

            li {
              list-style: none;
              font-family: "Bai Jamjuree";
              font-weight: 600;
              font-size: 14px;
              line-height: 26px;
              letter-spacing: 0.7px;
              color: #38fea8;
            }
          }

          p {
            align-self: center;
            margin: 10px 0 30px 0;

            font-family: "Bai Jamjuree";
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.7px;
            color: #ffffff;
          }
        }

        .egg-activity-content-show {
          height: 300px;
          transition: all ease-in 0.3s;
        }
        .egg-activity-content-show::-webkit-scrollbar,
        .egg-activity-content::-webkit-scrollbar {
          display: none;
        }

        .egg-activity-content-show {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      }
    }

    .egg-description-chest {
      max-width: 1069px;
    }

    .egg-features {
      background: #0f0c0899 0% 0% no-repeat padding-box;
      border: 1px solid #6b6152;
      border-radius: 6px;
      width: 100%;
      max-width: 585px;
      padding: 20px 35px;
      display: flex;
      align-items: center;

      ul {
        margin: 0;
        padding: 0 0 0 0px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        li {
          list-style: none;
          display: flex;

          .circle {
            max-width: 10px;
            min-width: 10px;
            width: 27px !important;
            height: 9px;
            background-color: #38fea8;
            border-radius: 100%;
            margin: 5px 10px 0 0;
          }

          .text {
            font-family: "Bai Jamjuree";
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.marketplace-egg-info-container-chest {
  max-width: 1674px;
}

@media (max-width: 1282px) {
  .marketplace-egg-info-container {
    .bottom {
      .egg-description {
        .description-content {
          padding: 20px 0 20px 0;
        }
      }
    }
  }
}

@media (max-width: 1190px) {
  .marketplace-egg-info-container {
    .bottom {
      .egg-description-container {
        .egg-description {
          .description-header {
            .header-attributes {
              margin: 0 0 0 70px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1134px) {
  .marketplace-egg-info-container {
    .top {
      .egg-info {
        padding: 30px 20px;
      }
    }
  }
}

@media (max-width: 1080px) {
  .marketplace-egg-info-container {
    .top {
      .egg-info {
        max-width: 415px;
      }
    }

    .bottom {
      .egg-description {
        .description-header {
          .header-attributes {
            display: none;
          }
        }

        .description-content {
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .attributes-header-mobile {
            display: flex !important;
          }

          .description-text {
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .marketplace-egg-info-container {
    .bottom {
      .parents-info {
        .parents-container {
          margin: 40px 10px 0 10px;
          .father,
          .mother {
            min-width: 0;
            min-height: fit-content;
          }
        }

        .breed-info {
          min-width: 95%;
        }
      }
    }
  }
}

@media (max-width: 973px) {
  .marketplace-egg-info-container {
    .top {
      .image-container {
        .egg-image-container {
          align-content: center;

          .egg-image {
            height: 200px;
          }
        }
      }
    }
  }
}

@media (max-width: 880px) {
  .marketplace-egg-info-container {
    .bottom {
      .egg-description-container {
        .egg-activity {
          height: 570px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .marketplace-egg-info-container {
    .top,
    .bottom {
      flex-direction: column;
    }

    .top {
      .egg-info {
        max-width: 100%;

        .sell-container {
          margin: 0 10px 20px 10px !important;

          button {
            width: 310px !important;
            font-size: 20px;
          }
        }

        h1 {
          text-align: center;
        }

        .current-price {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .buttons-container {
          margin: 20px 0 0 0;
        }

        .owned {
          position: relative;
          text-align: center;
          margin: 20px 0 0 0;
          bottom: auto;
          left: auto;
        }
      }
    }

    .bottom {
      .egg-features {
        min-width: 100%;
      }

      .egg-description-container {
        .egg-activity {
          height: fit-content;
        }
      }

      .parents-info {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .marketplace-egg-info-container {
    .top {
      .image-container {
        .egg-image-container {
          align-content: center;

          .egg-image {
            height: 200px;
          }
        }
      }
    }

    .bottom {
      .parents-info {
        .parents-container {
          display: flex;
          flex-direction: column;

          .father,
          .mother {
            display: flex;
            align-items: center;
          }

          .father > div,
          .mother > div {
            align-self: flex-start;
          }
        }
      }
    }
  }
}
