.posts-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 50px 0 0 0;
  padding: 0 20px;
}

.blog-post-selector-container {
  display: flex;
  gap: 51px;
  margin: 55px 0 0 0;
  background-color: #5e523b80;
  border-radius: 23px;
  padding: 0;

  li {
    list-style: none;
    text-align: center;
    font: normal normal 600 18px/24px Bai Jamjuree;
    letter-spacing: 0.18px;
    color: #0f0c08;
    text-transform: uppercase;
    padding: 10px 20px;
    cursor: pointer;
    border: 2px solid transparent;
  }

  .active {
    border: 2px solid #0f0c08;
    border-radius: 23px;
  }
}

@media (max-width: 656px) {
  .blog-post-selector-container {
    gap: 0;
  }
}

@media (max-width: 500px) {
  .blog-post-selector-container {
    max-width: 355px;
    li {
      font: normal normal 600 14px/24px Bai Jamjuree;
      padding: 5px 10px;
    }
  }
}
