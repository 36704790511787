.loading {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  h1 {
    color: #ffffff;
  }
}
hr {
  margin: 40px 0 10px 0;
  border: 1px solid #ffdd8b;
}
.withdraw-table-button {
  background: #37f6a359 0% 0% no-repeat padding-box;
  border: none;
  font-family: "Bai Jamjuree";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 4px;
}
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.price-alert {
  color: #ffffff;
  text-align: center;
  width: 100%;
  margin: 0 0 20px 0;
}
.accept-button,
.cancel-button {
  margin: 10px 0 0 0 !important;
}
.save-button {
  margin: 10px 0 20px 0;
  border: 0;
  background-color: transparent;
  width: 100%;
  max-width: 185px;
  height: 37px;
  background-size: auto;

  font-family: "Bai Jamjuree";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #ffdd8b;
  text-transform: uppercase;
}

.already-approve-button {
  font-size: 12px;
  cursor: default !important;
}

.withdraw-button {
  font-size: 12px;
  cursor: pointer !important;
}

.input {
  width: 100%;
  max-width: 380px;
  margin: 17px 0 0 0;
  height: 40px;
  padding: 0 0 0 23px;

  background: #16120e80 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 4px;
}

.input::placeholder,
.input {
  font-family: "Bai Jamjuree";
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #ffffff;
}

.input:focus {
  border: 2px solid #6b6152;
  outline: none;
}

.profile-details {
  background: #18130d80 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;

  h1 {
    font-family: "Bai Jamjuree";
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.5px;
    color: #ffdd8b;
    text-transform: uppercase;
  }

  .content {
    margin: 58px 0 0 0;
    display: flex;
    gap: 108px;

    .details {
      min-width: 480px;

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-item {
          display: flex;
          flex-direction: column;

          label {
            font-family: "Bai Jamjuree";
            font-size: 14px;
            letter-spacing: 0.7px;
            color: #ffffff;
          }
        }
      }

      .connections {
        margin: 34px 0 0 0;

        h4 {
          font-family: "Bai Jamjuree";
          font-size: 14px;
          letter-spacing: 0.7px;
          color: #ffffff;
        }

        .pair-wallet-container {
          display: flex;
          flex-direction: column;
          align-items: start;

          h4 {
            font-size: 15px;
          }

          p {
            font-size: 18px;
            margin: 10px 0;
            color: #ffffff;
          }
        }

        span {
          margin: 7px 0 0 0;
          font-family: "Bai Jamjuree";
          font-size: 12px;
          letter-spacing: 0.6px;
          color: #ffffff;
        }

        .buttons-container {
          display: flex;
          gap: 10px;
        }

        .wallet-container {
          margin: 17px 0 0 0;
          max-width: 380px;

          .wallet {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 6px 0 0 0;

            .wallet-info {
              background: #16120e80 0% 0% no-repeat padding-box;
              border: 1px solid #6b6152;
              border-radius: 4px;
              padding: 10px;
              min-width: 154px;
              display: flex;
              justify-content: center;
              gap: 5px;

              img {
                width: 20px;
              }

              .wallet-name {
                margin: 0;
                font: normal normal medium 14px/18px Bai Jamjuree;
                font-family: "Bai Jamjuree";
                font-size: 14px;
                letter-spacing: 0.7px;
                color: #ffffff;
              }
            }

            .connect-button {
              background: #ffdd8b4d 0% 0% no-repeat padding-box;
              border: 1px solid #77694b;
              border-radius: 4px;
              padding: 10px 30px;
              height: fit-content;

              font-family: "Bai Jamjuree";
              font-weight: 600;
              font-size: 9px;
              letter-spacing: 0.9px;
              color: #ffdd8b;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .profile-image {
      width: 100%;

      h4 {
        font-family: "Bai Jamjuree";
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #ffffff;

        img {
          width: 15px;
        }
      }

      .image-selected {
        width: 131px;
        height: 131px;
        background: #30291b 0% 0% no-repeat padding-box;
        border-radius: 4px;
        margin: 21px 0 0 0;

        img {
          width: 131px;
        }
      }

      .image-selector {
        margin: 16px 0 0 0;
        display: flex;
        max-width: 131px;
        flex-wrap: wrap;
        gap: 6px;

        .image-selector-item {
          cursor: pointer;
          width: 28px;
          height: 28px;
          background: #30291b 0% 0% no-repeat padding-box;
          border-radius: 4px;

          img {
            width: 28px;
          }
        }
      }

      .withdraw-container {
        margin: 40px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        h2 {
          font-size: 25px;
        }

        p,
        span {
          color: #ffffff;
        }

        span {
          margin: 10px 0 0 0;
        }

        p {
          margin: 30px 0 0 0;
          font-size: 18px;
        }
      }
    }
  }

  .table-container {
    overflow: auto;

    .withdraws-table {
      width: 100%;
      min-width: 450px;
      margin: 12px 0 0 0;
      border: 1px solid #377b5e;
      border-radius: 8px;
      color: #ffffff;
      font-family: "Bai Jamjuree";

      border-spacing: 0;
      border-collapse: separate;
      border-radius: 8px;
      max-height: 500px;
      overflow: auto;

      thead {
        background: #38fea84d 0% 0% no-repeat padding-box;
        border-radius: 8px 0 0 0;
        padding: 5px 15px;
        border-spacing: 0;
        border-collapse: separate;

        tr {
          th {
            padding: 5px 10px;
          }

          th:first-child {
            border-radius: 7px 0 0 0;
          }
          th:last-child {
            border-radius: 0 7px 0 0;
          }
        }
      }

      tbody {
        background: transparent
          linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 3px 6px #00000029;

        .odd {
          background: #38fea826 0% 0% no-repeat padding-box;
        }

        tr {
          td {
            font-family: "Bai Jamjuree";
            font-size: 13px;
            letter-spacing: 0.6px;
            padding: 5px 10px;
          }
        }

        tr:last-child {
          td:last-child {
            border-radius: 0 0 8px 0;
          }
          td:first-child {
            border-radius: 0 0 0 8px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .profile-details {
    .content {
      gap: 30px;

      .details {
        min-width: 350px;
      }
    }
  }
}

@media (max-width: 933px) {
  .profile-details {
    padding: 20px 40px;
  }
}

@media (max-width: 877px) {
  .profile-details {
    .content {
      .details {
        min-width: 300px;
      }
    }
  }
}

@media (max-width: 827px) {
  .profile-details {
    .content {
      .details {
        min-width: 280px;
      }
    }
  }
}

@media (max-width: 783px) {
  .profile-details {
    .content {
      gap: 20%;
    }
  }
}

@media (max-width: 640px) {
  .profile-details {
    .content {
      gap: 10%;
    }
  }
}

@media (max-width: 560px) {
  .already-approve-button {
    margin: 20px 0 0 0 !important;
  }
  .save-button {
    align-self: center;
    margin: 0;
  }
  .profile-details {
    .content {
      flex-direction: column-reverse;
      gap: 50px;
      margin: 34px 0 0 0;

      .details {
        min-width: 100%;

        .approve-container {
          display: flex;
          flex-direction: column;
        }

        form {
          .form-item {
            .input {
              max-width: 100%;
            }
          }
        }

        .connections {
          .wallet-container {
            max-width: 100%;
          }
        }
      }

      .profile-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image-selected {
          width: 210px;
          height: 210px;

          img {
            width: 210px;
          }
        }

        .image-selector {
          max-width: 210px;
          gap: 4px;

          .image-selector-item {
            width: 31px;
          }
        }

        .withdraw-container {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 387px) {
  .profile-details {
    padding: 10px;
  }
}

@media (max-width: 327px) {
  .profile-details {
    padding: 10px;

    .content {
      .details {
        .connections {
          .wallet-container {
            .wallet {
              .wallet-info {
                padding: 5px;
                min-width: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 282px) {
  .profile-details {
    padding: 5px;

    .content {
      .details {
        .connections {
          .wallet-container {
            .wallet {
              .wallet-info {
                padding: 5px;

                .wallet-name {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
