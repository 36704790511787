.parents-info {
  background: #0f0c0899 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  width: 100%;
  max-width: 585px;
  display: flex;
  flex-direction: column;

  .parents-info-header {
    height: 43px;
    width: 100%;
    background: #ffdd8b4d 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    padding: 0 20px;

    p {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 1px;
      color: #ffdd8b;
      text-transform: uppercase;
    }
  }

  .parents-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 40px 0 0 0;

    h2 {
      color: #ffffff;
    }

    .parent {
      background: #30291b 0% 0% no-repeat padding-box;
      border: 1px solid #77694b;
      border-radius: 12px;
      min-width: 239px;
      min-height: 204px;
      padding: 0 10px;
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        gap: 10px;
      }

      div > span {
        font-family: "Bai Jamjuree";
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
      }

      .fheral-img-container {
        background: url("../../../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;

        .fheral-img {
          max-height: 132px;
        }
      }

      p {
        margin: 0;
        font-family: "Bai Jamjuree";
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #ffffff;
        text-transform: uppercase;
        align-self: center;
      }
    }

    .father > div > span {
      color: #7ee9ff;
    }

    .mother > div > span {
      color: #ffa5c7;
    }
  }
}

@media (max-width: 1081px) {
  .parents-info {
    .parents-container {
      gap: 10px;

      .parent:first-child {
        margin: 0 0 0 10px;
      }
      .parent:last-child {
        margin: 0 10px 0 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .parents-info {
    .parents-container {
      .parent {
        width: 200px;
        min-width: 0;
      }
    }
  }
}

@media (max-width: 880px) {
  .parents-info {
    .parents-container {
      flex-direction: column;
      align-items: center;
      padding: 0 0 40px 0;

      .parent {
        width: 80%;
        min-width: 0;
        margin: 0 10px !important;
      }
    }
  }
}

@media (max-width: 800px) {
  .parents-info {
    max-width: 100%;

    .parents-container {
      .parent {
        height: 300px;

        .fheral-img-container {
          .fheral-img {
            max-height: 215px;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .parents-info {
    max-width: 100%;

    .parents-container {
      .parent {
        height: 300px;

        .fheral-img-container {
          .fheral-img {
            max-height: 200px;
            margin: 0 0 0 40px;
          }
        }
      }
    }
  }
}
