.container {
  display: flex;
  gap: 40px;

  .male-container,
  .female-container {
    max-width: 465px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .breeds-count-label {
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
      align-self: center;
      margin: 20px 0 0 0;
      width: 165px;
      height: 35px;

      font-family: "Bai Jamjuree";
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.8px;
      color: #443928;
      text-transform: uppercase;
    }

    .status {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.8px;
      color: #0f0c08;
      text-transform: uppercase;
      margin: 15px 0 0 0;
    }
  }

  .fheral-box {
    height: fit-content;
    background-color: #413828;
    border-radius: 8px;
    padding: 0 0 20px 0;
    min-height: 630px;

    .box-header {
      background-color: #5e523b;
      border-radius: 8px 8px 0 0;
      min-height: 70px;
      display: flex;
      align-items: center;

      img {
        width: 35px;
        margin: 0 0 0 25px;
      }

      p {
        font-family: "Bai Jamjuree";
        font-size: 22px;
        line-height: 40px;
        font-weight: bold;
        letter-spacing: 1.1px;
        color: #ffdd8b;
        text-transform: uppercase;
        margin: 0 0 0 15px;
      }
    }

    .box-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100% - 70px);
      cursor: pointer;

      p {
        margin: 20px 0 0 0;
        font-family: "Bai Jamjuree";
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 1.1px;
        color: #ffffff;
      }
    }

    .box-content-selected {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      p {
        margin: 20px 0 0 0;
        font-family: "Bai Jamjuree";
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 1.1px;
        color: #ffffff;
      }
    }
  }

  .breeding-main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 366px;
    width: 100%;
    margin: 70px 0 0 0;

    .egg-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #5e523b80 0% 0% no-repeat padding-box;
      border: 1px solid #30291b;
      border-radius: 8px;
      min-height: 311px;

      img {
        width: 157px;
      }
    }

    .price-container {
      margin: 15px 0 0 0;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      background: #5e523b80 0% 0% no-repeat padding-box;
      border: 1px solid #30291b;
      border-radius: 8px;
      min-height: 91px;

      span {
        margin: 10px 0 0 25px;
        font-family: "Bai Jamjuree";
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
      }

      p {
        display: flex;
        align-self: center;
        margin: 10px 0 0 0;
        text-align: center;

        span {
          font-family: "Bai Jamjuree";
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0.8px;
          color: #ffffff;
          text-transform: uppercase;
        }

        img {
          margin: 0 5px 0 0;
        }
      }
    }

    button {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin: 20px 0 0 0;
      width: 370px;
      height: 100px;
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .select-pet-item-container {
    display: flex;
    gap: 10px;
    margin: 20px 0 0 0;
    flex-wrap: wrap;
    max-width: 890px;
    max-height: 610px;
    overflow: auto;
    justify-content: center;
  }

  .select-pet-title {
    text-align: center;
    font-family: "Bai Jamjuree";
    font-size: 35px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0.8px;
  }
  .close-button {
    margin: 20px 0 0 0;
    width: 240px;
    height: 40px;
    font-family: "Bai Jamjuree";
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: 0.9px;
    color: #ffdd8b;
    text-transform: uppercase;
    border: 0;
    background-color: transparent;
  }
}

@media (max-width: 1575px) {
  .container {
    gap: 20px;

    .male-container,
    .female-container {
      min-width: 350px;
    }

    .breeding-main-content {
      max-width: 300px;
    }
  }
}

@media (max-width: 1355px) {
  .container {
    .male-container,
    .female-container {
      min-width: 310px;
    }

    .fheral-box {
      min-height: 530px;
    }
  }
}

@media (max-width: 1250px) {
  .container {
    gap: 10px;

    .male-container,
    .female-container {
      max-width: 320px;
    }

    .breeding-main-content {
      button {
        margin: 40px 0 0 0;
      }
    }
  }
}

@media (max-width: 1220px) {
  .container {
    .breeding-main-content {
      max-width: 230px;

      .egg-container {
        img {
          width: 120px;
        }
      }

      .price-container {
        span {
          margin: 10px 0 0 10px;
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .container {
    .male-container,
    .female-container {
      max-width: 300px;
      min-width: 100px;
    }
    .fheral-box {
      .box-header {
        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1115px) {
  .container {
    flex-direction: column;

    .male-container,
    .female-container {
      max-width: 100%;
      min-width: 100px;
    }

    .fheral-box {
      .box-content {
        margin: 100px 0 0 0;
      }
    }

    .breeding-main-content {
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .egg-container {
        max-width: 450px;
        width: 100%;

        img {
          width: 170px;
        }
      }

      .price-container {
        span {
          margin: 10px 40px 0 40px;
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 785px) {
  .container {
    padding: 0 20px 20px 20px;
  }
}
