.related-items-container {
  padding: 37px 0 40px 0;
  background: #d5d0c9 0% 0% no-repeat padding-box;
  min-height: 500px;
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  flex-direction: column;
  align-items: center;
  padding: 0 20px 50px 20px;

  .related-items-header {
    background: #5e523b4d 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    max-width: 1432px;
    height: 46px;
    display: flex;
    align-items: center;
    margin: 50px 0 0 0;

    span {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 1px;
      color: #443928;
      text-transform: uppercase;
      padding: 0 0 0 20px;
    }
  }

  .row {
    gap: 20px;
    display: flex;
    justify-content: center;
    max-width: 1432px;
    margin: 40px 0 0 0;
  }
}
