.chests-pets-section {
  .chests-pets-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    margin: 107px 0 0 0 !important;
    text-align: center;
    font-family: "Bai Jamjuree";
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.48px;
    color: #0f0c08;
    text-transform: uppercase;
  }

  p {
    margin: 35px 0 0 0;
    max-width: 1457px;
    text-align: center;
    font-family: "Bai Jamjuree";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #0f0c08;
  }

  .bg-img {
    background-image: url("../../../../public/assets/images/backgrounds/features-section-bg-gradient.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100vw;

    .chests {
      z-index: 1;
      margin: 61px 0 0 0;

      .arkha-box {
        width: 100%;
        max-width: 507px;
        max-height: 374px;
      }

      h3,
      p {
        text-align: left;
      }

      p {
        max-width: 554px;
        margin: 27px 0 0 0;
      }

      h3 {
        font-size: 38px;
        margin: 0 !important;
      }

      .market-img {
        width: 100%;
        max-width: 835px;
        max-height: 485px;
        margin: 0 40px 0 0;
      }
    }

    .pets {
      margin: 100px 0 0 0;

      h3,
      p {
        text-align: left;
      }

      p {
        max-width: 568px;
        margin: 20px 0 0 0;
      }

      h3 {
        font-size: 38px;
        margin: 20px 0 0 0 !important;
      }

      .pets-img {
        width: 100%;
        max-width: 835px;
        max-height: 485px;
      }

      .pets-content {
        padding: 0 0 0 140px;

        .pets-items {
          display: flex;
          flex-direction: column;
          gap: 9px;
          margin: 36px 0 0 0;
        }
      }
    }

    .play-button {
      margin: 80px 0 0 0;
    }
  }
}

@media (max-width: 2300px) {
  .chests-pets-section {
    .bg-img {
      background-size: contain;
    }
  }
}

@media (min-width: 1920px) {
  .chests-pets-section {
    height: 145%;
  }
}

@media (max-width: 1460px) {
  .chests-pets-section {
    h3 {
      margin: 70px 0 0 0 !important;
    }

    .bg-img {
      .chests {
        margin: 40px 0 0 0;

        .arkha-box {
          margin: 40px 0 0 0;
        }

        .market-img-container {
          display: flex;
          justify-content: center;

          .market-img {
            margin: 0;
          }
        }

        .chests-content {
          margin: 90px 0 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            text-align: center;
          }
        }
      }

      .pets {
        .pets-content {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          .pets-items {
            align-items: center;
            width: 90%;
          }

          p {
            text-align: center;
          }
        }

        .pets-img-container {
          display: flex;
          justify-content: center;
          margin: 69px 0 0 0;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .chests-pets-section {
    .bg-img {
      .play-button {
        margin: 0;
      }
    }
  }
}
