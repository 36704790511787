.modal-backdrop {
  z-index: 1;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vw;
}

.inventory-item {
  max-width: 320px !important;
}

.marketplace-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background: transparent linear-gradient(180deg, #201a14 0%, #443928 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  max-width: 330px;
  width: 100%;

  .wishlist {
    position: absolute;
    top: 13px;
    left: 13px;
    cursor: pointer;
  }

  .image-container-berserker {
    background-image: url("../../../../public/assets/images/marketplace/backgrounds/berserker-bg.png");
  }

  .image-container-izarian {
    background-image: url("../../../../public/assets/images/marketplace/backgrounds/izarian-bg.png");
  }

  .image-container-soulhunter {
    background-image: url("../../../../public/assets/images/marketplace/backgrounds/soulhunter-bg.png");
  }

  .image-container-alchemist {
    background-image: url("../../../../public/assets/images/marketplace/backgrounds/alchemist-bg.png");
  }

  .image-container-wasteller {
    background-image: url("../../../../public/assets/images/marketplace/backgrounds/wasteller-bg.png");
  }

  .image-container-fheral,
  .image-container-egg {
    background-image: url("../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
    position: relative;

    .for-sale,
    .banned {
      position: absolute;
      width: auto;
      height: 90%;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .image-container-common {
    background-image: url("../../../../public/assets/images/marketplace/backgrounds/common-bg.png");
  }

  .image-container-alchemist,
  .image-container-berserker,
  .image-container-common,
  .image-container-fheral,
  .image-container-izarian,
  .image-container-soulhunter,
  .image-container-wasteller,
  .image-container-egg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 245px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px 8px 0 0;

    .img {
      width: 100%;
      min-width: 350px;
      margin: 20px 0 0 0;
    }

    .img-pet {
      width: auto;
      height: 100%;
    }
  }

  .image-container-egg {
    .img {
      width: 40%;
      min-width: 0;
      margin: 20px 0 0 0;
    }
  }

  .name {
    text-align: center;
    font-family: "Bai Jamjuree";
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.9px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 18px 0 0 0 !important;
  }

  .details {
    display: flex;
    gap: 8px;
    margin: 12px;

    .genre {
      width: 29px;
      height: 29px;
    }

    .type-item {
      display: flex;
      background: transparent linear-gradient(180deg, #141f1a 0%, #1e533e 100%)
        0% 0% no-repeat padding-box;
      border: 1px solid #377b5e;
      border-radius: 100%;
      padding: 5px;
      width: 29px;
      height: 29px;

      img {
        width: 100%;
      }
    }

    .tags {
      display: flex;
      gap: 8px;
      align-items: center;
      span {
        font: normal normal bold 10px/13px Bai Jamjuree;
        letter-spacing: 1px;
        color: #37f5a2;
        text-transform: uppercase;
        padding: 6px 15px;
        background: transparent
          linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #377b5e;
        border-radius: 4px;
      }
    }
  }

  .price {
    display: flex;
    align-items: center;
    margin: 23px 0 0 0;
    gap: 5px;

    span {
      text-align: left;
      font: normal normal bold 18px/23px Bai Jamjuree;
      letter-spacing: 1.8px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .buy-button,
  .detail-button {
    margin: 5px 0 10px 0;
    min-height: 70px;
  }

  .buy-button {
    min-width: 230px;
    font-weight: bold;
    font-family: "Bai Jamjuree";
    font-size: 15px;
    letter-spacing: 0.75px;
    color: #131b17;
    text-transform: uppercase;
    z-index: 2;
  }

  .detail-button {
    min-width: 189px;
    filter: drop-shadow(10px 10px 20px #0000004f);
    background-color: transparent;
    border: none;
    font-family: "Bai Jamjuree";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffdd8b;
    text-transform: uppercase;

    a {
      color: #ffdd8b;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .hatch-button {
    width: 189px;
    height: 36px;
    font-family: "Bai Jamjuree";
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 1px;
    color: #131b17;
    text-transform: uppercase;
    background-size: cover;
    background-position: center;
    margin: -20px 0 30px 0;
  }

  .modal-details {
    visibility: hidden;
    width: 100%;
    max-width: 320px;
    position: absolute;
    right: -18%;
    top: -20%;
    z-index: 1;
    background: transparent
      linear-gradient(180deg, #131b17f2 0%, #1e533ef2 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 15px 15px 20px #00000085;
    border: 1px solid #377b5e;
    border-radius: 8px;

    .modal-details-header {
      background: #38fea84d 0% 0% no-repeat padding-box;
      padding: 12px 25px;

      span {
        font-family: "Bai Jamjuree";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.8px;
        color: #38fea8;
        text-transform: uppercase;
      }
    }

    .description {
      margin: 19px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .info {
        width: 100%;

        .info-item {
          display: flex;
          width: 100%;

          .info-item-name,
          .info-item-value {
            width: 50%;
            font-family: "Bai Jamjuree";
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.7px;
            color: #38fea8;
            text-transform: uppercase;
            margin: 0;
          }

          .info-item-name {
            text-align: end;
          }
          .info-item-value {
            color: #fff;
            margin: 0 0 0 15px;
          }
        }
      }

      .text {
        padding: 0 20px;
        font: normal normal normal 16px/24px Bai Jamjuree;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }

    .stats {
      display: flex;
      column-gap: 35px;
      flex-wrap: wrap;
      padding: 13px 20px 25px 20px;

      .attack,
      .life,
      .power,
      .agility,
      .energy {
        display: flex;
        margin: 7px 0 0 0;
        gap: 9px;

        span {
          font: normal normal normal 16px/24px Bai Jamjuree;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}

.marketplace-item:hover .modal-details {
  visibility: visible;
}

/* SKELETON STYLES */
.wishlist-skeleton {
  width: 35px;
  height: 35px;
  border-radius: 4px;
}
.item-name-skeleton {
  width: 200px;
  height: 30px;
  border-radius: 4px;
  margin: 14px 0 0 15px;
}
.item-characteristics-skeleton-container {
  display: flex;
  margin: 40px 0 0 0;
  gap: 10px;

  .item-characteristic-skeleton {
    width: 35px;
    height: 35px;
    border-radius: 4px;
  }
}
.item-price-skeleton {
  width: 100px;
  height: 20px;
  border-radius: 4px;
}
.item-button-skeleton {
  width: 170px;
  height: 40px;
  border-radius: 4px;
  margin: 20px 0 30px 0;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #554834;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(32, 26, 20, 0.2) 20%,
      rgba(32, 26, 20, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@media (max-width: 895px) {
  .marketplace-item {
    min-width: 80%;

    .modal-details {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .marketplace-item {
    min-width: 100%;
  }
}
