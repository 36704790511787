.support-container {
  display: flex;
  gap: 261px;
  background: #18130d88 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  padding: 30px 60px;

  div {
    h1 {
      font-family: "Bai Jamjuree";
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #ffdd8b;
      text-transform: uppercase;
    }

    p {
      font-family: "Bai Jamjuree";
      font-size: 12px;
      letter-spacing: 0.6px;
      color: #ffffff;
    }

    .faq-container {
      .faq-item {
        margin: 30px 0 0 0;

        .faq-header {
          border-bottom: 1px solid #ffdd8b88;
          padding: 0 0 10px 0;

          span {
            font-family: "Bai Jamjuree";
            font-size: 14px;
            letter-spacing: 0.7px;
            color: #ffdd8b;

            img {
            }
          }
        }

        .faq-content {
          margin: 5px 0 0 0;

          span {
            font-family: "Bai Jamjuree";
            font-size: 12px;
            letter-spacing: 0.6px;
            color: #ffffff;
          }

          .options-container {
            margin: 13px 0 0 0;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .option {
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 5px 10px;
              border: 1px solid #77694b;
              border-radius: 3px;

              .checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 12px;
                height: 12px;
                border: 1px solid #ffffff;
                border-radius: 3px;

                .checked {
                  width: 6px;
                  height: 6px;
                  background: #ffffff 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                }
              }

              span {
              }
            }

            .active {
              background: #ffdd8b4d 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }
  }

  form {
    width: 100%;

    .form-item {
      display: flex;
      flex-direction: column;

      label {
        font-family: "Bai Jamjuree";
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #ffffff;
        margin: 19px 0 9px 0;
      }

      input {
        width: 100%;
        max-width: 586px;
        color: #ffffff;
        padding: 0 0 0 20px;

        background: #16120e88 0% 0% no-repeat padding-box;
        border: 1px solid #6b6152;
        border-radius: 4px;
        height: 40px;
      }

      input:focus {
        outline: none;
        border: 2px solid #6b6152;
      }
    }

    .submit {
      margin: 173px 0 0 0;
      border: 0;
      background-color: transparent;
      width: 100%;
      max-width: 185px;
      height: 37px;
      background-size: auto;

      font-family: "Bai Jamjuree";
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.8px;
      color: #ffdd8b;
      text-transform: uppercase;
    }
  }
}

.support-container > div > p {
  margin: 39px 0 0 0;
}

.support-container > div {
  max-width: 397px;
}

@media (max-width: 1430px) {
  .support-container {
    gap: 50px;
  }
}

@media (max-width: 1200px) {
  .support-container {
    gap: 30px;
    padding: 30px;
  }
}

@media (max-width: 950px) {
  .support-container {
    flex-direction: column;

    form {
      .submit {
        margin: 50px 0 0 0;
      }
    }
  }
  .support-container > div {
    max-width: 100%;
  }
}
