.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../public/assets/images/backgrounds/footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 919px;

  .footer-title {
    text-align: center;
    font: normal normal bold 48px/60px Bai Jamjuree;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;

    padding: 197px 0 0 0 !important;
  }

  .footer-text {
    text-align: center;
    font: normal normal 600 18px/28px Bai Jamjuree;
    letter-spacing: 0px;
    color: #ffffff;
    max-width: 1000px;
    margin: 42px 0 0 0;
  }

  .play-free {
    margin: 53px 0 0 0;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    margin: 197px 0 0 0;

    ul {
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
    }

    li {
      list-style: none;
      text-align: left;
      font: normal normal 600 16px/20px Bai Jamjuree;
      letter-spacing: 0.8px;
      color: #ffffff;
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .footer-logo {
    margin: 42px 0 0 0;
    max-width: 188px;
  }

  .sub-footer {
    background: #272117b3 0% 0% no-repeat padding-box;
    width: 100%;
    margin: 42px 0 0 0;
    padding: 20px 180px 0 180px;
    display: flex;
    justify-content: space-between;

    p {
      text-align: center;
      font: normal normal normal 16px/24px Bai Jamjuree;
      letter-spacing: 0px;
      color: #ffffff;
    }

    ul {
      display: flex;
      gap: 34px;

      li {
        list-style: none;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        a {
          background: none;
          border: none;
          width: 25px;
          height: 23px;
        }
      }
    }

    .telegram {
      background-image: url("../../../public/assets/images/icons/telegram-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .youtube {
      background-image: url("../../../public/assets/images/icons/youtube-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 30px;
    }

    .twitter {
      background-image: url("../../../public/assets/images/icons/twitter-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .github {
      background-image: url("../../../public/assets/images/icons/git-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

@media (max-width: 1150px) {
  .footer {
    .footer-text {
      max-width: 800px;
    }
  }
}

@media (max-width: 973px) {
  .footer {
    .footer-content {
      padding: 0 20px;
    }
    .sub-footer {
      padding: 20px 20px 0 20px;
    }
  }
}

@media (max-width: 645px) {
  .footer {
    .footer-title {
      padding: 119px 0 0 0 !important;
    }

    .footer-links {
      margin: 75px 0 0 0;

      ul {
        padding: 0 20px;
      }

      ul:last-child {
        margin: 20px 0 0 0;
      }
    }

    .sub-footer {
      flex-direction: column;
      align-items: center;
    }
  }
}
