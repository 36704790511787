.pet-to-show-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 420px;

  img {
    margin: 30px 0 0 0;
    width: 100%;
  }
}

.pet-to-show-footer-container {
  p {
    font-family: "Bai Jamjuree";
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1.2px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 30px 0 0 30px;
  }
}

@media (max-width: 1355px) {
  .pet-to-show-container {
    min-height: 300px;
  }
}

@media (max-width: 1115px) {
  .pet-to-show-container {
    img {
      width: 60%;
    }
  }
}

@media (max-width: 500px) {
  .pet-to-show-container {
    img {
      width: 100%;
    }
  }
}
