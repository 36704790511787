.security-container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  background: #18130d80 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  padding: 30px 60px;

  h1 {
    font-family: "Bai Jamjuree";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffdd8b;
    text-transform: uppercase;
  }

  h4 {
    font-family: "Bai Jamjuree";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #ffdd8b;
    text-transform: uppercase;

    margin: 38px 0 0 0 !important;
  }

  p {
    font-family: "Bai Jamjuree";
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #ffffff;

    margin: 15px 0 50px 0;
  }

  .input-container {
    display: inline-flex;
    gap: 42px;
    margin: 20px 0 0 0;
    align-items: center;
    position: relative;
    width: fit-content;

    label {
      min-width: 140px;

      font-family: "Bai Jamjuree";
      font-size: 14px;
      letter-spacing: 0.7px;
      color: #ffffff;
    }

    input {
      min-width: 465px;
      min-height: 38px;

      background: #16120e80 0% 0% no-repeat padding-box;
      border: 1px solid #6b6152;
      border-radius: 4px;
    }

    input,
    input::placeholder {
      font-family: "Bai Jamjuree";
      font-size: 14px;
      letter-spacing: 0.7px;
      color: #ffffff;
      padding: 0 0 0 19px;
    }

    input:focus {
      border-width: 2px;
      outline: none;
    }

    .error-input {
      border: 2px solid red;
    }

    .show-pass,
    .hide-pass {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 20px;
      height: 25px;
    }

    .show-pass {
      background: url("../../../../../public/assets/images/marketplace/icons/show-pass.svg")
        no-repeat scroll;
    }

    .hide-pass {
      background: url("../../../../../public/assets/images/marketplace/icons/hide-pass.svg")
        no-repeat scroll;
    }
  }

  .password-strength-container {
    margin: 5px 0 0 183px;
    max-width: 464px;

    span {
      display: block;
      text-align: center;
      width: 100%;
      margin: 3px 0 0 0;

      font-family: "Bai Jamjuree";
      font-size: 14px;
      letter-spacing: 0.7px;
      color: #ffffff;
    }
  }

  .password-strength {
    background: #ffdc8b46 0% 0% no-repeat padding-box;
    border-radius: 3px;
    width: 100%;

    height: 7px;

    .power-point {
      background: #ffdc8ba4 0% 0% no-repeat padding-box;
      border-radius: 3px;
      width: 1%;
      height: 7px;
      transition: all ease-in-out 0.3s;
    }
  }

  .error-container {
    margin: 20px 0 0 260px;
    border: 1px solid red;
    width: fit-content;
    padding: 5px 30px;
    border-radius: 3px;
    background-color: #ff000032;
    span {
      color: red;
    }
  }

  .buttons-container {
    margin: 55px 0 0 185px;
    display: flex;
    gap: 25px;

    button {
      border: 0;
      background-color: transparent;
      width: 100%;
      max-width: 183px;
      height: 37px;
      background-size: auto;

      font-family: "Bai Jamjuree";
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.8px;
      color: #ffdd8b;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 1050px) {
  .security-container {
    .input-container {
      width: 100%;

      input {
        min-width: 0;
        width: 100%;
      }
    }

    .error-container {
      margin: 20px 0 0 0;
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width: 1040px) {
  .security-container {
    .buttons-container {
      margin: 55px 0 0 0;
    }
  }
}

@media (max-width: 920px) {
  .security-container {
    padding: 20px;
  }
}

@media (max-width: 840px) {
  .security-container {
    .input-container {
      gap: 10px;
    }

    .password-strength-container {
      margin: 5px 0 0 150px;
    }
  }
}

@media (max-width: 800px) {
  .security-container {
    p {
      margin: 15px 0 20px 0;
    }

    .input-container {
      flex-direction: column;

      label {
        width: 100%;
        text-align: start;
      }

      .show-pass,
      .hide-pass {
        top: 40px;
      }
    }

    .password-strength-container {
      margin: 5px 0 0 0;
      max-width: 100%;
    }
  }
}

@media (max-width: 481px) {
  .security-container {
    .buttons-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      margin: 50px 0 0 0;
    }
  }
}
