.slick-slider-item {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 620px;
  width: 1048px;

  display: flex !important;
  justify-content: center;
  position: relative;

  .slick-slider-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: -20%;
    z-index: 2;
    background: var(--background-champion-content);
    background-repeat: no-repeat;

    width: 748px;
    height: 226px;

    h3 {
      margin: 50px 0 0 0 !important;
      text-align: center;
      font-family: "Bai Jamjuree";
      font-size: 48px;
      font-weight: bold;
      letter-spacing: -0.48px;
      color: #ffffff;
      text-transform: uppercase;
    }

    p {
      text-align: center;
      font-family: "Bai Jamjuree";
      font-size: 16px;
      letter-spacing: 0px;
      color: #39ffa9;
      max-width: 649px;
    }
  }
}

.izarian-slide {
  background-image: var(--izarian-desktop-img);
}
.berserker-slide {
  background-image: var(--berserker-desktop-img);
}
.wasteller-slide {
  background-image: var(--wasteller-desktop-img);
}
.soulhunter-slide {
  background-image: var(--soulhunter-desktop-img);
}
.alchemist-slide {
  background-image: var(--alchemist-desktop-img);
}

@media (max-width: 1200px) {
  .slick-slider-item {
    .slick-slider-item-content {
      bottom: -8%;
      width: 648px;
      background-size: contain;

      h3 {
        margin: 0 !important;
      }
      p {
        max-width: 549px;
      }
    }
  }

  .slick-slide {
    div {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 850px) {
  .slick-slider-item {
    .slick-slider-item-content {
      bottom: 0%;
      width: 548px;
      background-size: contain;

      h3 {
        font-size: 30px;
        margin: -50px 0 0 0 !important;
      }
      p {
        max-width: 449px;
        font-size: 13px;
      }
    }
  }

  .slick-slide {
    div {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 750px) {
  .izarian-slide {
    background-image: var(--izarian-mobile-img);
  }
  .berserker-slide {
    background-image: var(--berserker-mobile-img);
  }
  .wasteller-slide {
    background-image: var(--wasteller-mobile-img);
  }
  .soulhunter-slide {
    background-image: var(--soulhunter-mobile-img);
  }
  .alchemist-slide {
    background-image: var(--alchemist-mobile-img);
  }

  .slick-slider-item {
    .slick-slider-item-content {
      background: transparent;

      h3 {
        font-size: 36px;
        margin: -70px 0 0 0 !important;
      }

      p {
        margin: 18px 0 0 0;
        max-width: 300px;
        font-size: 16px;
      }
    }
  }
}
