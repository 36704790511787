.status-container {
  background: #5e523b80 0% 0% no-repeat padding-box;
  border: 1px solid #30291b;
  border-radius: 4px;

  .status-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;

    span {
      width: 50%;
      color: #ffffff;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      letter-spacing: 0.7px;
    }
  }

  .odd {
    background: #3e342580 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
  }
}
