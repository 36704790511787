.next-arrow {
  background: url("../../../public/assets/images/icons/arrow-right.svg");
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 100px;
  right: 17%;
  top: 43%;
  z-index: 1;

  &::before {
    content: "";
  }

  &:hover {
    background: url("../../../public/assets/images/icons/arrow-right.svg");
    background-position: center;
    background-size: cover;
  }
}

.items-slider-next-arrow {
  right: -5% !important;
  top: 50% !important;
}

.items-slider-prev-arrow {
  left: -4% !important;
  top: 50% !important;
}

.marketplace-slider-next-arrow {
  right: -3% !important;
  top: 50% !important;
}

.marketplace-slider-prev-arrow {
  left: -2% !important;
  top: 50% !important;
}

.prev-arrow {
  background: url("../../../public/assets/images/icons/arrow-left.svg");
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 100px;
  left: 17%;
  top: 43%;
  z-index: 1;

  &::before {
    content: "";
  }

  &:hover {
    background: url("../../../public/assets/images/icons/arrow-left.svg");
    background-position: center;
    background-size: cover;
  }
}

@media (max-width: 1650px) {
  .prev-arrow {
    left: 10%;
  }
  .next-arrow {
    right: 10%;
  }
}

@media (max-width: 1340px) {
  .prev-arrow {
    left: 0%;
  }
  .next-arrow {
    right: 0%;
  }
}

@media (max-width: 640px) {
  .marketplace-slider-next-arrow {
    right: -5% !important;
    width: 70px;
    height: 70px;
  }

  .marketplace-slider-prev-arrow {
    left: -4% !important;
    width: 70px;
    height: 70px;
  }
}
