.privacy-container {
  max-width: 1320px;
  margin: auto;
  padding: 5vw 20px;
  color: #ffffff;

  h1 {
    margin: 0 0 50px 0 !important;
  }
}
