.blog-post-card {
  max-width: 464px;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;

  .blog-post-card-content {
    padding: 18px 46px;
  }

  h4 {
    display: flex;
    gap: 4.5px;
  }

  p {
    margin: 6px 0 0 0;
  }

  .blog-post-skeleton-img {
    width: 100%;
    height: 262px;
    border-radius: 12px 12px 0px 0px;
  }

  .blog-post-skeleton-title {
    width: 372px;
    height: 60px;
    border-radius: 12px;
  }

  .blog-post-skeleton-text {
    width: 372px;
    height: 120px;
    margin: 7px 0 0 0;
    border-radius: 12px;
  }

  .blog-post-skeleton-footer-item {
    width: 31px;
    height: 20px;
    border-radius: 12px;
  }

  .blog-post-footer {
    position: relative;
    margin: 26px 0 0 0;
    display: flex;
    gap: 22px;

    span {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .share {
    position: absolute;
    right: 0;
    width: 21px;
    height: 25px;
  }
  .separator {
    margin: 4px 0 0 0;
    width: 5px;
    height: 54px;
    background: #38fea8 0% 0% no-repeat padding-box;
    border-radius: 7px;
  }

  .heart {
    width: 16px;
    height: 14px;
  }

  .minus {
    width: 16px;
    height: 16px;
  }
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #e5e1d9;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: "";
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@media (max-width: 500px) {
  .blog-post-card {
    .blog-post-skeleton-title {
      width: 100%;
    }

    .blog-post-skeleton-text {
      width: 100%;
    }
  }
}
