.blogSection {
  background: url("../../../../public/assets/images/backgrounds/blog-section-bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1079px;
  height: 110vh;

  .blog-section-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .blogSection-title {
    margin: 85px 0 20px 0 !important;
    text-align: center;
    font: normal normal bold 48px/24px Bai Jamjuree;
    letter-spacing: -0.48px;
    color: #0f0c08;
    text-transform: uppercase;
  }

  .blogSection-text {
    margin: 20px 0 0 0;
    font: normal normal 600 20px/24px Bai Jamjuree;
    letter-spacing: 0px;
    color: #0f0c08;

    max-width: 1428px;
  }

  .load-more-btn {
    margin: 60px 0 0 0;
  }
}

@media (max-width: 1471px) {
  .blogSection {
    .mobile-background-extension {
      background: #d5d0c9;
      height: 400px;
    }
  }
}

@media (max-width: 1280px) {
  .blogSection {
    background-size: contain;
  }
}

@media (max-width: 1200px) {
  .blogSection {
    .blogSection-text {
      max-width: 850px;
      text-align: center;
    }
  }
}

@media (max-width: 1129px) {
  .blogSection {
    background-position: bottom;
    padding: 0 0 40px 0;
    height: auto;
  }
}

@media (max-width: 600px) {
  .blogSection {
    .blogSection-title {
      line-height: 50px;
    }
  }
}
