.main-section {
  background-image: url("../../../../public/assets/images/backgrounds/main-bg.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  width: 100%;
  height: 100vh;

  .main-section-content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }

  .social-media-container {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 40%;
    display: flex;
    flex-direction: column;
    gap: 38px;
    background: #000000b3 0% 0% no-repeat padding-box;
    padding: 10px;
    justify-content: center;
    align-items: center;
    align-content: center;

    a {
      width: 24px;
      height: 20px;
      border: 0;
      background-color: transparent;
    }
    .telegram {
      background-image: url("../../../../public/assets/images/icons/telegram-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .youtube {
      background-image: url("../../../../public/assets/images/icons/youtube-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .twitter {
      background-image: url("../../../../public/assets/images/icons/twitter-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .github {
      background-image: url("../../../../public/assets/images/icons/git-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .logo-small {
    width: 93px;
    height: 100px;
    margin: 220px 0 0 0;
  }

  h1 {
    margin: 43px 0 0 0 !important;
    text-align: center;
    font-size: 60px;
    font-family: "Bai Jamjuree";
    font-weight: bold;
    letter-spacing: -0.6px;
    color: #ffffff;
    text-shadow: 6px 6px 20px #0000007d;
    text-transform: uppercase;
    filter: drop-shadow(6px 6px 20px #0000007d);
    max-width: 1100px;
  }

  .play-button {
    margin: 81px 0 0 0;
  }

  .platforms-container {
    display: flex;
    gap: 51px;
    margin: 61px 0 0 0;

    .platforms-buttons-container {
      display: flex;
      gap: 16px;

      .platform-button {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 10px;
        background: transparent
          linear-gradient(180deg, #131b17b3 0%, #1e533eb3 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #377b5e;
        border-radius: 6px;
        width: 131px;
        height: 44px;

        span {
          padding: 0 0 0 10px;
          text-align: left;
          font-size: 9px;
          font-family: "Bai Jamjuree";
          letter-spacing: 0px;
          color: #ffffff;
        }
      }

      .mobile-icon {
        display: none;
        width: 74px;
        height: 44px;
        border: 0;
        background-color: transparent;
      }

      .windows {
        background-image: url("../../../../public/assets/images/icons/windows-icon-mobile.svg");
      }

      .ios {
        background-image: url("../../../../public/assets/images/icons/ios-icon-mobile.svg");
      }

      .android {
        background-image: url("../../../../public/assets/images/icons/android-icon-mobile.svg");
      }
    }
  }
}

@media (min-width: 1920px) {
  .main-section {
    height: 133vh;
  }
}

@media (max-width: 1670px) {
  .main-section {
    .logo-small {
      margin: 120px 0 0 0;
    }
    h1 {
      font-size: 50px;
    }
  }
}

@media (max-width: 1075px) {
  .main-section {
    .social-media-container {
      top: 45%;
    }
  }
}

@media (max-width: 865px) {
  .main-section {
    height: 80vh;
    .social-media-container {
      top: 52%;
    }
  }
}

@media (max-width: 660px) {
  .main-section {
    .social-media-container {
      display: none;
    }

    .platforms-container {
      gap: 19px;
      margin: 35px 0 0 0;

      .platforms-buttons-container {
        .mobile-icon {
          display: flex;
        }

        .platform-button {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .main-section {
    .logo-small {
      width: 75px;
      height: 80px;
    }
    h1 {
      margin: 28px 0 0 0 !important;
      font-size: 43px;
      letter-spacing: -0.48px;
      max-width: 354px;
    }
    .play-button {
      margin: 77px 0 0 0;
    }

    .platforms-container {
      flex-direction: column;
      align-items: center;
      .blockchain-platform {
        width: 125px;
      }
    }
  }
}

/* HEIGHT MEDIA QUERIES */
@media (max-height: 1055px) {
  .main-section {
    height: 100vh;
  }
}

@media (max-height: 847px) {
  .main-section {
    height: 110vh;
  }
}

@media (max-height: 770px) {
  .main-section {
    height: 120vh;
  }
}

@media (max-height: 705px) {
  .main-section {
    height: 130vh;
  }
}

@media (max-height: 650px) {
  .main-section {
    height: 140vh;
  }
}

@media (max-height: 605px) {
  .main-section {
    height: 150vh;
  }
}

@media (max-height: 560px) {
  .main-section {
    height: 170vh;
  }
}

@media (max-height: 495px) {
  .main-section {
    height: 200vh;
  }
}

@media (max-height: 425px) {
  .main-section {
    height: 230vh;
  }
}
