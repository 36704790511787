.header {
  display: flex;
  justify-content: center;
  background: transparent;
  padding: 21px 121px 16px 121px;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mobile-navbar {
      display: none;
    }

    .mobile-navbar-user {
      display: none;
      padding: 20px 0 0 0;
      width: 100vw;
      height: 100vh;
      position: fixed;
      background: transparent linear-gradient(181deg, #131b17 0%, #131b17 100%)
        0% 0% no-repeat padding-box;
      z-index: 2;
      top: 0;
      left: 0;
      overflow: hidden;
      transition: all ease-in-out 0.3s;
      margin-left: 1000px;

      .user-menu-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;

        .close-icon-green {
          display: none;
          margin: 25px 20px 0 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      hr {
        border-top: 2px solid #37f5a280;
      }

      .user-menu-user-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 35px 0 0 0;

        .user-menu-icon {
          width: 53px;
          height: 53px;
          background: #39ffa940 0% 0% no-repeat padding-box;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 28px;
            height: 28px;
          }
        }

        .user-menu-info {
          display: flex;
          flex-direction: column;

          .username,
          .email {
            font-size: 16px;
            letter-spacing: 0.8px;
            font-family: "Bai Jamjuree";
          }
          .username {
            color: #fff;
            font-weight: bold;
          }

          .email {
            color: #3ffeab;
            text-decoration: underline;
            font-weight: 200;
          }
        }
      }

      .user-menu-content {
        display: flex;
        flex-direction: column;

        ul {
          display: flex;
          flex-direction: column;
          gap: 30px;
          margin: 40px 0 0 0;

          a {
            text-decoration: none;
          }

          li {
            display: flex;
            list-style: none;
            font-size: 16pt;
            letter-spacing: 0.8px;
            line-height: 20px;
            color: #fff;
            font-family: "Bai Jamjuree";

            img {
              width: 17px;
              margin: 0 10px 0 0;
            }

            .support {
              width: 22px;
              margin: 0 7px 0 0;
            }
          }
        }

        button {
          display: flex;
          text-align: left;
          font-size: 14pt;
          font-family: "Bai Jamjuree";
          letter-spacing: 0.7px;
          color: #131b17;
          text-transform: uppercase;
          padding: 10px;
          width: 100%;
          max-width: 256px;
          margin: 45px 0;
          img {
            margin: 0 10px 0 10px;
          }
        }

        .login-container {
          display: flex;
          justify-content: center;

          .login {
            margin: 20px;

            a {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                transform: rotate(180deg);
                position: absolute;
                right: 100px;
              }
            }
          }
        }
      }

      .user-menu-footer {
        padding: 50px 10px 50px 20px;

        p {
          font-size: 14pt;
          color: #fff;
          font-family: "Bai Jamjuree";
          letter-spacing: 0.7px;
          line-height: 18px;
        }

        .item {
          max-height: 50px;
          background: transparent
            linear-gradient(190deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #377b5e;
          border-radius: 8px;
          width: 100%;
          max-width: 257px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 17px;
          align-self: center;
          font-weight: 600;
          font-size: 16pt;
          font-family: "Bai Jamjuree";
          letter-spacing: 0.8px;
          color: #ffffff;
          text-transform: uppercase;

          img {
            width: 22px;
          }
        }
      }

      .user-menu-content,
      .user-menu-footer {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 290px;
        margin-top: 40px;
      }
    }

    .mobile-navbar-navigation {
      display: none;
      padding: 20px 0 0 0;
      width: 100vw;
      height: 100vh;
      position: fixed;
      background: transparent linear-gradient(181deg, #201a14 0%, #443928 100%)
        0% 0% no-repeat padding-box;
      z-index: 2;
      top: 0;
      left: 0;
      overflow: hidden;
      transition: all ease-in-out 0.3s;
      margin-left: -1000px;

      .user-menu-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;

        .close-icon {
          display: none;
          margin: 25px 20px 0 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      hr {
        border-top: 2px solid #ffdd8b80;
      }

      .navigation-menu-content {
        padding: 50px 0 0 0;

        ul {
          padding: 0;
          display: flex;
          flex-direction: column;

          li {
            cursor: pointer;
            text-decoration: none;
            color: #fff;
            text-transform: uppercase;
            font-size: 14pt;
            font-family: "Bai Jamjuree";
            letter-spacing: 0.7px;
            font-weight: 600;

            list-style: none;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            gap: 5px;

            a {
              color: #fff;
              text-decoration: none;
              width: 100%;
            }

            svg {
              transition: all ease-in-out 0.3s;
            }
          }

          li:hover,
          .active {
            background-color: #ffdd8b33;
            border-radius: 4px;
            color: #ffdd8b;

            svg {
              path {
                fill: #ffdd8b;
              }
            }
          }

          .active {
            svg {
              transform: rotate(180deg);
            }
          }

          .navigation-mobile-dropdown {
            padding: 0 0 0 30px;
            transition: all ease-in-out 0.3s;
            height: 0;
            overflow: hidden;

            ul {
              display: flex;
              gap: 10px;

              li {
                cursor: pointer;
                color: #ffffff;
                list-style: none;
                font-size: 14pt;
                letter-spacing: 0.7px;
                line-height: 18px;
                font-family: "Bai Jamjuree";
                padding: 5px 0 5px 5px;
              }

              li:hover {
                background-color: #ffdd8b33;
                border-radius: 4px;
                color: #ffdd8b;
              }
            }
          }

          .show-navigation-mobile-dropdown {
            padding: 10px 0 20px 30px;
            max-height: 230px;
            height: fit-content;
            transition: all ease-in-out 0.3s;
          }
        }
      }

      .navigation-menu-footer {
        padding: 30px 0 0 0;

        .item {
          text-decoration: none;
          max-height: 50px;
          background: transparent
            linear-gradient(190deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #377b5e;
          border-radius: 8px;
          width: 100%;
          max-width: 258px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 10px 17px;
          align-self: center;
          font-weight: 600;
          font-size: 14pt;
          font-family: "Bai Jamjuree";
          letter-spacing: 0.8px;
          color: #37f5a2;
          text-transform: uppercase;
          margin: 20px 0 0 0;

          img {
            width: 34px;
            border-radius: 100%;
          }
        }

        .play-button {
          border-color: #77694b !important;
          background: #ffdd8b33 0% 0% no-repeat padding-box !important;
          color: #ffdd8b !important;

          div {
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffdd8b40;
            border-radius: 100%;
            width: 34px;
            height: 34px;

            img {
              width: 15px;
              margin: 0 0 0 4px;
              border-radius: 0%;
            }
          }
        }
      }

      .navigation-menu-content,
      .navigation-menu-footer {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 290px;
      }
    }

    .logo,
    .mobile-logo {
      display: flex;
      align-items: center;
      transition: all ease-in-out 0.3s;
      z-index: 3;

      img {
        max-width: 140px;
        max-height: 49px;
      }
      a {
        text-decoration: none;
      }
      span {
        font-family: "Bai Jamjuree";
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #37f5a2;
        border: 1px solid #37f5a2;
        border-radius: 6px;
        font-size: 10px;
        padding: 4px 9px;
      }
    }

    .mobile-navbar {
      .mobile-navbar-item {
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 48px;
        width: 48px;

        border-radius: 8px;

        div {
          width: 34px;
          height: 34px;
          background: #39ffa940 0% 0% no-repeat padding-box;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      .mobile-navbar-item-user {
        background: transparent
          linear-gradient(190deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #377b5e;
      }

      .mobile-navbar-item-hamburguer {
        background-color: #ffdd8b33;
        border: 1px solid #77694b;
        transition: all ease-out 0.1s;
      }
      .hide-icon-menu-open {
        opacity: 0;
      }
    }

    .navbar-items {
      display: flex;
      gap: 20px;
      position: relative;

      .nav-item {
        display: flex;
        max-height: 50px;

        .item {
          border-radius: 8px;
          max-height: 50px;
          background: transparent
            linear-gradient(190deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #377b5e;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 17px;
          align-self: center;
          text-decoration: none;

          font-weight: 600;
          font-size: 16px;
          font-family: "Bai Jamjuree";
          letter-spacing: 0.8px;
          color: #ffffff;
          text-transform: uppercase;
        }

        .play-button {
          max-height: 50px;
          border-color: #77694b !important;
          background: #ffdd8b33 0% 0% no-repeat padding-box !important;
          color: #ffdd8b !important;
          padding: 6px 17px;

          div {
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffdd8b40;
            border-radius: 100%;
            width: 34px;
            height: 34px;

            img {
              width: 15px;
              margin: 0 0 0 4px;
            }
          }
        }

        .user {
          gap: 3px;
          padding: 5px 17px;

          div {
            width: 34px;
            height: 34px;
            background: #39ffa940 0% 0% no-repeat padding-box;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .profile-dropdown {
        display: flex;
        flex-direction: column;
        color: white;
        position: absolute;
        right: 0;
        top: 50px;
        min-width: 222px;
        z-index: 1;

        background: transparent
          linear-gradient(182deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #377b5e;
        border-radius: 8px;

        .login-container {
          display: flex;
          justify-content: center;

          .login {
            margin: 20px;

            a {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                transform: rotate(180deg);
                position: absolute;
                right: 30px;
              }
            }
          }
        }

        .dropdown-header {
          display: flex;
          padding: 10px;

          .dropdown-user-icon {
            width: 34px;
            height: 34px;
            background: #39ffa940 0% 0% no-repeat padding-box;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .user-info {
            display: flex;
            flex-direction: column;
            padding: 0 0 0 10px;

            .username {
              text-align: left;
              font-size: 12px;
              font-family: "Bai Jamjuree";
              letter-spacing: 0.6px;
              color: #ffffff;
            }

            .id {
              text-align: left;
              text-decoration: underline;
              font-size: 12px;
              font-family: "Bai Jamjuree";
              letter-spacing: 0.6px;
              color: #39ffa9;
            }
          }
        }

        ul {
          padding: 0 0 0 24px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          a {
            text-decoration: none;
            color: #fff;
          }

          li {
            display: flex;
            align-items: center;
            list-style: none;

            img {
              width: 14px;
              margin: 0 8px 0 0;
            }

            .support {
              width: 18px;
              margin: 0 5px 0 -1px;
            }
          }
        }

        button {
          display: flex;
          text-align: left;
          font-size: 14px;
          font-family: "Bai Jamjuree";
          letter-spacing: 0.7px;
          color: #131b17;
          text-transform: uppercase;
          padding: 10px;
          width: 90%;

          img {
            margin: 0 10px 0 10px;
          }
        }

        .logout {
          margin: 0 0 10px 0;
        }
      }
    }
  }

  .navigation-tabs {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0 0;

    .nav-links-container {
      display: flex;
      border-radius: 8px;
      border: 1px solid #77694b;
      padding: 0;
      background: #0f0c08 0% 0% no-repeat padding-box;
      overflow: hidden;

      .nav-item {
        display: flex;
        align-items: center;
        gap: 5px;
        transition: all ease-in-out 0.3s;
        list-style: none;
        font-size: 14px;
        font-family: "Bai Jamjuree";
        letter-spacing: 0.7px;
        color: #ffffff;
        text-transform: uppercase;
        padding: 15px 36px;
        border-left: 1px solid #77694b;
        cursor: pointer;
        max-height: 54px;

        a,
        span {
          display: flex;
          align-items: center;
          gap: 2px;
          text-decoration: none;
          color: #ffffff;

          svg {
            transition: all 0.3s;
          }
        }

        .navigation-dropdown {
          max-height: 0;
          padding: 0;
          overflow: hidden;
          position: absolute;
          z-index: 1;
          top: 145px;
          background: transparent
            linear-gradient(181deg, #201a14 0%, #443928 100%) 0% 0% no-repeat
            padding-box;
          border-radius: 4px;
          transition: all ease-in-out 0.3s;

          ul {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
              list-style: none;

              a {
                font-size: 12pt;
                letter-spacing: 0.7px;
                font-family: "Bai Jamjuree";
                line-height: 18px;
                cursor: pointer;
                padding: 5px 15px;
                color: #ffffff !important;
              }
            }

            li:hover {
              background-color: #ffdd8b33;
              a {
                color: #ffdd8b !important;
              }
            }
          }
        }

        .show-navigation-dropdown {
          border: 1px solid #77694b;
          padding: 20px 10px;
          max-height: 336px;
        }
      }
      .nav-item:hover {
        background: #ffdd8b4d 0% 0% no-repeat padding-box;

        span,
        a {
          color: #ffdd8b !important;
        }

        svg {
          transition: all ease-in-out 0.3s;
          path {
            fill: #ffdd8b;
          }
        }
      }
      .active {
        background: #ffdd8b4d 0% 0% no-repeat padding-box;
        color: #ffdd8b !important;
        transition: all ease-in-out 0.3s;

        span,
        a {
          color: #ffdd8b !important;
        }

        svg {
          transform: rotate(180deg);
          transition: all ease-in-out 0.3s;
          path {
            fill: #ffdd8b;
          }
        }
      }
    }

    .search {
      width: 100%;
      max-width: 528px;
      list-style: none;
      color: white;

      input {
        border: 1px solid #6b6152;
        border-radius: 8px;
        height: 54px;
        width: 100%;
        padding: 0 0 0 50px;
        color: #fff;
        background: url("../../../../public/assets/images/icons/search.svg")
          no-repeat scroll 20px 15px;
        background-color: #0f0c08;

        &:focus {
          outline: 2px solid #6b6152;
        }
      }

      input::-webkit-input-placeholder {
        text-align: left;
        font: normal normal medium 16px/26px Bai Jamjuree;
        letter-spacing: 0px;
        color: #ffffffb3;
        padding: 0 0 0 13px !important;
      }

      input:-moz-placeholder {
        /* Firefox 18- */
        text-align: left;
        font: normal normal medium 16px/26px Bai Jamjuree;
        letter-spacing: 0px;
        color: #ffffffb3;
        padding: 0 0 0 13px !important;
      }

      input::-moz-placeholder {
        /* Firefox 19+ */
        text-align: left;
        font: normal normal medium 16px/26px Bai Jamjuree;
        letter-spacing: 0px;
        color: #ffffffb3;
        padding: 0 0 0 13px !important;
      }

      input:-ms-input-placeholder {
        text-align: left;
        font: normal normal medium 16px/26px Bai Jamjuree;
        letter-spacing: 0px;
        color: #ffffffb3;
        padding: 0 0 0 13px !important;
      }
    }
  }

  .slider-banner {
    background: url("../../../../public/assets/images/marketplace/backgrounds/the-deep-dark-bg.png");
    min-height: 460px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 130px;

    .content {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;

      h3 {
        text-align: left;
        font-weight: bold;
        font-family: "Orbitron";
        font-size: 56px;
        letter-spacing: 0px;
        text-transform: uppercase;
        max-width: 530px;
        color: #fff;
        line-height: 50px;
        padding: 5px 0;

        background: linear-gradient(to right, #186d48, transparent);
      }

      p {
        text-align: left;
        font: normal normal 600 18px/28px Bai Jamjuree;
        letter-spacing: 0px;
        color: #ffffff;
        max-width: 610px;
      }

      button {
        border: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: transparent;
        width: 265px;
        height: 90px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 11px;
        font-size: 20px !important;
        font-family: "Bai Jamjuree";
        font-weight: bold;
        letter-spacing: 0px;
        color: #131b17;
        text-transform: uppercase;

        img {
          width: 25px;
        }
      }
    }

    .portraits {
      position: relative;
      width: 70%;

      img:last-child {
        right: 5%;
      }

      img:first-child {
        right: 20%;
      }

      img {
        position: absolute;
        z-index: 0;
        height: 100%;
        max-height: 460px;
      }
    }
  }

  .items-tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 0 0;

    .items-tabs {
      display: flex;

      border-radius: 8px;
      border: 1px solid #77694b;
      padding: 0;
      background: #0f0c08 0% 0% no-repeat padding-box;
      overflow: hidden;

      .item-tab {
        list-style: none;
        font-size: 14px;
        font-family: "Bai Jamjuree";
        letter-spacing: 0.7px;
        color: #ffffff;
        text-transform: uppercase;
        padding: 15px 26px;
        border-left: 1px solid #77694b;
        cursor: pointer;
        max-height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;

        .navbar-img {
          display: none;
          width: 30px;
          height: 30px;
          opacity: 30%;
        }

        &:hover {
          background: #ffdd8b4d 0% 0% no-repeat padding-box;
          color: #ffdd8b !important;
          .navbar-img {
            opacity: 100%;
          }
        }
      }

      .active {
        background: #ffdd8b4d 0% 0% no-repeat padding-box;
        color: #ffdd8b !important;

        .navbar-img {
          opacity: 100%;
        }
      }
    }

    .select-view-container {
      display: flex;

      padding: 0;
      width: fit-content;

      .select-view-item {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid #77694b;
        display: flex;
        align-content: center;
        list-style: none;
        padding: 0 15px;
        flex-wrap: wrap;

        &:hover {
          background-color: #5e523b;
        }
      }

      .active {
        background-color: #5e523b;
      }
    }
  }
}

@media (max-width: 1650px) {
  .header {
    .navigation-tabs {
      .nav-links-container {
        .nav-item {
          padding: 15px 25px;
        }
      }
    }
  }
}

@media (max-width: 1512px) {
  .header {
    .navigation-tabs {
      .search {
        width: auto;
      }
    }
  }
}

@media (max-width: 1420px) {
  .header {
    .items-tabs-container {
      .items-tabs {
        .item-tab {
          padding: 15px 20px;
        }
      }
    }
  }
}

@media (max-width: 1331px) {
  .header {
    .items-tabs-container {
      .items-tabs {
        .item-tab {
          padding: 15px 15px;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .header {
    padding: 21px 50px 16px 50px;
  }
}

@media (max-width: 1286px) {
  .header {
    .navigation-tabs {
      .nav-links-container {
        .nav-item {
          padding: 15px 20px;
        }
      }
    }
  }
}

@media (max-width: 1231px) {
  .header {
    .items-tabs-container {
      .items-tabs {
        .item-tab {
          padding: 15px 25px;
          .navbar-text {
            display: none;
          }

          .navbar-img {
            display: flex;
          }
        }
      }
    }
  }
}

@media (max-width: 1027px) {
  .header {
    .navigation-tabs {
      .nav-links-container {
        .nav-item {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.7px;
        }
      }
    }
  }
}

@media (max-width: 1010px) {
  .header {
    .items-tabs-container {
      .items-tabs {
        .item-tab {
          padding: 20px;

          .navbar-img {
            display: flex;
          }
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .header {
    .navigation-tabs {
      .nav-links-container {
        .nav-item {
          display: flex;
          letter-spacing: 0;
          padding: 15px 20px;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 955px) {
  .header {
    padding: 21px 20px 16px 20px;
    .navigation-tabs {
      .nav-links-container {
        .nav-item {
          display: flex;
          letter-spacing: 0;
          padding: 15px 20px;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 893px) {
  .header {
    .navigation-tabs {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .nav-links-container {
        width: fit-content;

        .nav-item {
          padding: 15px 36px;
        }
      }

      .search {
        align-self: self-end;
      }
    }
  }
}

@media (max-width: 855px) {
  .header {
    .items-tabs-container {
      .items-tabs {
        .item-tab {
          padding: 20px;

          .navbar-img {
            display: flex;
            width: 25px;
          }
        }
      }
    }
  }
}

@media (max-width: 803px) {
  .header {
    .items-tabs-container {
      .items-tabs {
        .item-tab {
          padding: 15px;

          .navbar-img {
            display: flex;
            width: 25px;
          }
        }
      }
    }
  }
}

@media (max-width: 783px) {
  .header {
    .navbar {
      .mobile-navbar {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .mobile-logo-user-open {
          display: none;
        }
      }

      .mobile-navbar-user {
        display: block;

        .user-menu-header {
          .close-icon-green {
            display: flex;
            cursor: pointer;
          }
        }
      }

      .show-mobile-menu-user {
        margin-left: 0;
      }

      .mobile-navbar-navigation {
        display: block;
        .user-menu-header {
          .close-icon {
            display: flex;
            cursor: pointer;
          }
        }
      }

      .show-mobile-menu-navigation {
        margin-left: 0;
      }

      .navbar-items {
        display: none;
      }

      .logo {
        display: none;
      }
    }

    .navigation-tabs {
      .nav-links-container {
        display: none;
      }

      .search {
        width: 100%;
        padding: 0;
        max-width: 100%;
      }
    }

    .slider-banner {
      padding: 0;
      flex-direction: column;

      .content {
        margin: 40px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 32px;
          line-height: 35px;
          text-align: center;
          max-width: 300px;
        }

        p {
          text-align: center;
          max-width: 400px;
        }
      }

      .portraits {
        width: 100%;
        height: 200px;

        img:last-child {
          right: 28%;
        }
        img:first-child {
          right: 35%;
        }
      }
    }
  }
}

@media (max-width: 745px) {
  .header {
    .navbar {
      flex-direction: column;
      gap: 20px;
    }
  }
}

@media (max-width: 714px) {
  .header {
    .items-tabs-container {
      flex-direction: column;

      .items-tabs {
        width: fit-content;
        overflow-x: scroll;

        .item-tab {
          padding: 20px;
        }
      }

      .items-tabs::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .items-tabs {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      .select-view-container {
        align-self: self-end;
        .select-view-item {
          padding: 15px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .header {
    .slider-banner {
      .portraits {
        img:last-child {
          right: 20%;
        }
        img:first-child {
          right: 30%;
        }
      }
    }
  }
}

@media (max-width: 536px) {
  .header {
    .navbar {
      .navbar-items {
        gap: 10px;
        .nav-item {
          .item {
            font-size: 14px;
            padding: 5px 10px;
            gap: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .header {
    .slider-banner {
      .portraits {
        img:last-child {
          right: 10%;
        }
        img:first-child {
          right: 25%;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .header {
    .slider-banner {
      .portraits {
        img:last-child {
          right: 0;
        }
        img:first-child {
          left: 0;
        }
      }
    }
  }
}
