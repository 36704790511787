.content {
  display: flex;
  gap: 20px;
  padding: 0 123px;
  margin: 0 0 157px 0;

  .sidebar {
    background: transparent linear-gradient(181deg, #131b17 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #377b5e;
    border-radius: 8px;
    padding: 20px 15px;
    width: 100%;
    max-width: 220px;

    h2 {
      display: flex;
      gap: 5px;
      font: normal normal 600 20px/25px Bai Jamjuree;
      letter-spacing: 1px;
      color: #37f8a4;
      text-transform: uppercase;

      img {
        width: 16px;
      }
    }

    ul {
      padding: 0;
      margin: 49px 0 0 0;
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        padding: 10px;
        border-radius: 4px;

        li {
          list-style: none;
          color: #ffffff;
          font-family: "Bai Jamjuree";
          font-size: 14px;
          letter-spacing: 0.7px;
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 13px;
          }

          .profile_details {
            width: 15px;
          }

          .inventory {
            width: 14px;
            height: 20px;
          }

          .support {
            width: 18px;
          }

          .security {
            width: 15px;
            height: 17px;
          }

          .download {
            width: 14px;
            height: 19px;
          }

          .activity {
            width: 16px;
            height: 12px;
          }
        }
      }

      a:hover,
      .active {
        background: #37f8a41a 0% 0% no-repeat padding-box;
      }
    }
  }

  .outlet {
    width: 100%;
  }
}

.content > div {
  display: flex;
  gap: 20px;
}

@media (max-width: 1300px) {
  .content {
    padding: 0 50px;
  }
}

@media (max-width: 1066px) {
  .content {
    .outlet {
      width: 75%;
    }
  }
}

@media (max-width: 954px) {
  .content {
    .outlet {
      width: 80%;
    }
  }
}

@media (max-width: 783px) {
  .content {
    padding: 0 20px;
    margin: 0 0 68px 0;

    .sidebar {
      max-width: 100%;

      ul {
        padding: 0 0 0 64px;
        margin: 20px 0 0 0;
      }
    }

    .outlet {
      width: 100%;
    }
  }

  .content > div {
    flex-direction: column;
  }
}
