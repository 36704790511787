.download-files {
  background: #18130d80 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  padding: 30px 60px 190px 60px;

  span {
    font-family: "Bai Jamjuree";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-family: "Bai Jamjuree";
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #ffdd8b;
      text-transform: uppercase;
    }

    .all-platforms {
      position: relative;
      cursor: pointer;
      background: #ffdd8b4d 0% 0% no-repeat padding-box;
      border: 1px solid #77694b;
      border-radius: 3px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 15px;
      padding: 5px 15px;

      .all-platforms-text {
        font-family: "Bai Jamjuree";
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #ffdd8b;
        text-transform: none;
      }

      .platforms-icon {
        width: 12px;
      }

      .arrow {
        width: 7px;
        margin: 0 0 0 10px;
        transition: all ease-in-out 0.3s;
      }

      .arrow-open {
        transform: rotate(180deg);
        transition: all ease-in-out 0.3s;
      }

      .all-platforms-dropdown {
        display: flex;
        z-index: 1;
        justify-content: center;
        position: absolute;
        min-width: 100%;
        background: #5b4f36 0% 0% no-repeat padding-box;
        border: 1px solid #77694b;
        right: 0;
        top: 100%;
        border-radius: 4px;
        padding: 10px;
        margin: 10px 0 0 0;

        ul {
          padding: 0;
          margin: 0;
          width: 100%;

          li {
            cursor: pointer;
            text-align: center;
            list-style: none;
            font-family: "Bai Jamjuree";
            font-weight: normal;
            font-size: 14px;
            letter-spacing: 0.7px;
            color: #ffdd8b;
            border-radius: 4px;
            padding: 5px;
          }

          li:hover {
            background: #423926 0% 0% no-repeat padding-box;
          }
        }
      }
    }
  }

  .pc {
    margin: 36px 0 0 0;
    display: flex;
    gap: 63px;

    .arker-1,
    .arker-2 {
      display: flex;
      flex-direction: column;

      span {
        margin: 0 0 24px 0;
      }

      button:last-child {
        margin-top: 10px;
      }
    }

    .arker-2 {
      button {
        margin: 0 0 0 53px;
      }
    }
  }

  .mobile {
    margin: 38px 0 0 0;
    display: flex;
    flex-direction: column;

    .mobile-buttons {
      margin: 25px 0 0 53px;
      display: flex;
      gap: 63px;
    }
  }

  .others {
    display: flex;
    flex-direction: column;
    margin: 57px 0 0 0;

    .others-buttons {
      margin: 25px 0 0 53px;
      display: flex;
      gap: 20px;
    }
  }

  .download-button {
    position: relative;
    background: transparent linear-gradient(195deg, #131b17 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #377b5e;
    border-radius: 8px;
    min-width: 300px;
    min-height: 50px;
    padding: 7px 17px 7px 40px;

    font-family: "Bai Jamjuree";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #ffffff;
    text-transform: uppercase;

    img {
      border-radius: 100%;
      width: 32px;
      position: absolute;
      left: 10px;
      top: 9px;
    }
  }

  .fit-button {
    min-width: 222px;
    padding: 7px 17px 7px 70px;
  }
}

@media (max-width: 1126px) {
  .download-files {
    padding: 20px 30px;

    .pc {
      .arker-2 {
        button {
          margin: 0 0 0 20px;
        }
      }
    }

    .mobile {
      .mobile-buttons {
        margin: 25px 0 0 20px;
      }
    }

    .others {
      .others-buttons {
        margin: 25px 0 0 20px;
      }
    }
  }
}

@media (max-width: 1067px) {
  .download-files {
    .download-button {
      min-width: 270px;
    }
  }
}

@media (max-width: 1020px) {
  .download-files {
    .download-button {
      min-width: 270px;
    }

    .fit-button {
      min-width: 160px;
    }
  }
}

@media (max-width: 995px) {
  .download-files {
    .pc {
      flex-direction: column;
      gap: 20px;

      .arker-1 {
        button {
          margin: 0 0 0 20px;
        }
      }
    }

    .mobile {
      margin: 20px 0 0 0;
      .mobile-buttons {
        flex-direction: column;
        gap: 10px;
      }
    }

    .others {
      margin: 20px 0 0 0;
      .others-buttons {
        flex-direction: column;
        gap: 10px;
      }
    }

    .download-button,
    .fit-button {
      max-width: 300px;
      width: 100%;
    }
  }
}

@media (max-width: 520px) {
  .download-files {
    .header {
      flex-direction: column;
      gap: 20px;

      h1,
      .all-platforms {
        width: 100%;
      }

      .all-platforms {
        max-width: 300px;
        align-self: self-start;
        justify-content: space-between;
      }
    }

    .pc {
      .arker-1,
      .arker-2 {
        button {
          margin: 0;
        }
      }
    }

    .mobile {
      .mobile-buttons {
        margin: 25px 0 0 0;
      }
    }

    .others {
      .others-buttons {
        margin: 25px 0 0 0;
      }
    }
  }
}

@media (max-width: 375px) {
  .download-files {
    .download-button,
    .fit-button {
      min-width: 100%;
    }
  }
}
