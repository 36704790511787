.account-button {
  background: #ffdd8b4d 0% 0% no-repeat padding-box;
  border: 1px solid #77694b;
  border-radius: 4px;
  padding: 10px 30px;
  height: fit-content;

  font-family: "Bai Jamjuree";
  font-weight: 600;
  font-size: 9px;
  letter-spacing: 0.9px;
  color: #ffdd8b;
  text-transform: uppercase;
  margin: 10px 0 0 0;
}

.account-button-header {
  min-height: 47px;
  background: transparent linear-gradient(190deg, #131b17 0%, #1e533e 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #377b5e;
  border-radius: 8px;
  width: 100%;
  max-width: 257px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 17px;
  align-self: center;
  font-weight: 600;
  font-size: 11pt;
  font-family: "Bai Jamjuree";
  letter-spacing: 0.8px;
  color: #ffffff;
  text-transform: uppercase;
}
