.main-container {
  display: flex;
  justify-content: center;
  padding: 55px 0 80px 0;
  background: url("../../../../public/assets/images/marketplace/backgrounds/login-register-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .login-container {
    position: relative;
    width: 100%;
    max-width: 663px;
    background: transparent linear-gradient(182deg, #131b17 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #377b5e;
    border-radius: 8px;
    padding: 50px 100px;
    opacity: 0.9;

    .close-button {
      position: absolute;
      right: 15px;
      top: 15px;
      background: transparent linear-gradient(182deg, #131b17 0%, #1e533e 100%)
        0% 0% no-repeat padding-box;
      border: 1px solid #377b5e;
      border-radius: 100%;
      padding: 10px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
        height: 15px;
      }
    }

    h1 {
      font-family: "Bai Jamjuree";
      font-weight: 600;
      font-size: 26px;
      line-height: 40px;
      letter-spacing: 1.3px;
      color: #ffffff;
      text-transform: uppercase;
    }

    hr {
      margin: 0 0 10px 0;
      border: 1px solid #377b5e;
    }

    p {
      font-family: "Bai Jamjuree";
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.7px;
      color: #39ffa9;
      margin: 0;
    }

    .form-container {
      display: flex;
      justify-content: center;
      margin: 40px 0 0 0;

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 380px;
        gap: 20px;

        .form-item {
          position: relative;

          label {
            font-family: "Bai Jamjuree";
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.7px;
            color: #ffffff;
          }

          .input-container {
            display: flex;
            margin: 12px 0 0 0;

            div {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 50px;
              height: 50px;
              background: #39ffa980 0% 0% no-repeat padding-box;
              border-radius: 4px 0 0 4px;

              img {
                width: 19px;
                height: 14px;
              }

              .password-icon {
                width: 22px;
                height: 22px;
              }
            }

            input {
              background: #131b17 0% 0% no-repeat padding-box;
              border: 1px solid #377b5e;
              border-radius: 0 4px 4px 0;
              height: 50px;
              width: 100%;
              color: #ffffff;
              padding: 0 0 0 20px;
            }

            input::placeholder {
              color: #ffffff;
            }

            input:focus {
              outline: none;
            }
          }

          .show-pass,
          .hide-pass {
            position: absolute;
            top: 50px;
            right: 10px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            width: 20px;
            height: 25px;
          }

          .show-pass {
            background: url("../../../../public/assets/images/marketplace/icons/show-pass.svg")
              no-repeat scroll;
          }

          .hide-pass {
            background: url("../../../../public/assets/images/marketplace/icons/hide-pass.svg")
              no-repeat scroll;
          }
        }

        .register_recover_pass {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        p > a {
          color: #ffffff;
        }

        .login-button {
          width: 225px;
          height: 70px;
          margin: 20px 0 0 0;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .main-container {
    margin: 55px 20px;
  }
}

@media (max-width: 540px) {
  .main-container {
    .login-container {
      padding: 50px;
    }
  }
}
@media (max-width: 440px) {
  .main-container {
    .login-container {
      padding: 20px;
    }
  }
}
