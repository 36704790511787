.features-section {
  background-color: #d8d1ca;

  .features {
    display: flex;
    justify-content: center;
    gap: 23.5px;
  }

  .bg-img {
    background-image: url("../../../../public/assets/images/backgrounds/features-section-bg-gradient.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;

    .start-journey-container {
      margin: 118px 0 0 0;
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 60px;
      padding: 0 40px;

      .start-journey {
        h2 {
          max-width: 500px;
          text-align: left;
          font-family: "Bai Jamjuree";
          font-size: 42px;
          font-weight: bold;
          letter-spacing: -0.42px;
          color: #0f0c08;
        }
        p {
          max-width: 527px;
          margin: 20px 0 0 0;
          text-align: left;
          font-family: "Bai Jamjuree";
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0px;
          color: #0f0c08;
        }
      }

      .start-journey-img {
        width: 835px;
        height: 485px;

        img {
          width: 100%;
        }
      }

      .start-journey-features {
        margin: 45px 0 0 0;
        display: grid;
        grid-template-columns: 242px 242px;
        grid-template-rows: 83px 83px;
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        div {
          display: flex !important;
          padding: 10px;
          gap: 10px;
          align-items: center;
          background-color: #9b9182;
          border-radius: 10px;

          span {
            text-align: left;
            font-family: "Bai Jamjuree";
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #0f0c08;
            line-height: 20px;
          }
        }
      }
    }

    .highlights {
      display: flex !important;
      flex-direction: column;
      align-items: center;

      margin: -180px 0 0 0;

      .highlights-content {
        display: flex !important;
        align-items: center;
        margin: 250px 0 0 0;
        gap: 23px;
        padding: 0 200px;

        img {
          width: 950px;
          height: auto;
        }

        .get-highlights {
          h2 {
            text-align: left;
            font-family: "Bai Jamjuree";
            font-size: 42px;
            font-weight: bold;
            letter-spacing: -0.42px;
            color: #0f0c08;
          }

          p {
            margin: 36px 0 0 0;
            text-align: left;
            font-family: "Bai Jamjuree";
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #0f0c08;
          }

          .highlights-items {
            display: flex !important;
            flex-direction: column;
            gap: 9px;
            margin: 36px 0 0 0;
          }
        }
      }

      .play-button {
        margin: 0 0 100px 0;
      }
    }
  }
}

@media (max-width: 2700px) {
  .features-section {
    .bg-img {
      background-size: contain;
      background-position: bottom;
    }
  }
}

@media (max-width: 1472px) {
  .features-section {
    .bg-img {
      .start-journey-container,
      .highlights {
        .highlights-content {
          img {
            width: 880px;
          }
        }
      }
    }
  }
}

@media (max-width: 1435px) {
  .features-section {
    .bg-img {
      .start-journey-container,
      .highlights {
        padding: 0 40px;
      }
    }
  }
}

@media (max-width: 1410px) {
  .features-section {
    .bg-img {
      .start-journey-container,
      .highlights {
        .highlights-content {
          img {
            width: 800px;
          }
        }
      }
    }
  }
}

@media (max-width: 1380px) {
  .features-section {
    .bg-img {
      .highlights {
        .highlights-content {
          img {
            width: 800px;
          }
        }
      }
    }
  }
}

@media (max-width: 1338px) {
  .features-section {
    .bg-img {
      .start-journey-container,
      .highlights {
        .highlights-content {
          img {
            width: 750px;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .features-section {
    .bg-img {
      .start-journey-container,
      .highlights {
        .highlights-content {
          img {
            width: 700px;
          }
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .features-section {
    .bg-img {
      .highlights {
        .highlights-content {
          img {
            width: 650px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .features-section {
    .bg-img {
      .start-journey-container {
        flex-direction: column;
        margin: 65px 0 0 0;

        .start-journey {
          h2 {
            text-align: center;
          }
          p {
            text-align: center;
          }
        }

        .start-journey-img {
          width: 570px;
          height: auto;
        }
      }

      .highlights {
        margin: 0;
        .highlights-content {
          margin: 0;
          flex-direction: column-reverse;

          img {
            width: 100%;
          }

          .get-highlights {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2,
            p {
              text-align: center;
            }

            .highlights-items {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 712px) {
  .features-section {
    .features {
      padding: 0 25px;
    }

    .bg-img {
      .start-journey-container,
      .highlights {
        padding: 0 25px;
      }

      .start-journey-container {
        flex-direction: column;
        margin: 65px 0 0 0;
        .start-journey {
          h2 {
            text-align: center;
          }
          p {
            text-align: center;
          }
        }

        .start-journey-img {
          width: 100%;
          height: auto;
        }

        .start-journey-features {
          grid-template-columns: 100%;
        }
      }

      .highlights {
        background-image: none;
        background-color: #d5d0c9;

        .play-button {
          margin: 53px 0 41px 0;
        }

        .highlights-content {
          flex-direction: column-reverse;
          padding: 0;

          .highlights-img {
            width: 100%;
            margin: 70px 0 0 0;
          }

          .get-highlights {
            margin: 52px 0 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            h2,
            p {
              text-align: center;
            }

            p {
              max-width: 354px;
            }
          }
        }
      }
    }

    .features {
      display: flex;
      justify-content: center;
      gap: 23.5px;

      .features-container {
        gap: 32px 0;
      }
    }
  }
}
