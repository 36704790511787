.header {
  display: flex;
  gap: 15px;

  .category-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 46px;
    background: #5e523b4d 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    padding: 0 15px;

    h1 {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 1px;
      color: #443928;
      text-transform: uppercase;
    }

    span {
      font-family: "Bai Jamjuree";
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.8px;
      color: #443928;
      text-transform: uppercase;
    }
  }

  .progress {
    background: #5e523b4d 0% 0% no-repeat padding-box;
    border-radius: 4px;
    min-width: 343px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding: 0;

    span {
      font-family: "Bai Jamjuree";
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .progress-bar {
      margin: 2px 0 0 0;
      background: #5e523b4d 0% 0% no-repeat padding-box;
      border: 1px solid #443928;
      border-radius: 4px;
      width: 100%;
      height: 14px;

      .power-point {
        background: #5e523b 0% 0% no-repeat padding-box;
        border: 1px solid #443928;
        border-radius: 4px;
        width: 1%;
        height: 100%;
        transition: all ease-in-out 0.3s;
      }
    }
  }
}

.load-more {
  margin: 20px 0 40px 0;
  align-self: center;
  background-color: transparent;
  border: none;
  width: 100%;
  max-width: 226px;
  min-height: 48px;

  text-transform: uppercase;
  font-family: "Bai Jamjuree";
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: #443928;
}

.content {
  margin: 30px 0 0 0;
  gap: 15px;
  display: flex;
  flex-direction: column;

  .achivement-item {
    display: flex;
    align-items: center;
    background: transparent linear-gradient(100deg, #201a14 0%, #443928 100%) 0%
      0% no-repeat padding-box;
    border-radius: 8px;

    .achivement-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #30291b 0% 0% no-repeat padding-box;
      border: 2px solid #77694b;
      border-radius: 8px;
      min-width: 100px;
      height: 100px;

      img {
        min-width: 75px;
        height: 75px;
      }
    }

    .achivement-content {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      h4 {
        margin: 0 0 0 54px !important;
        font-family: "Bai Jamjuree";
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.9px;
        color: #ffffff;
        text-transform: uppercase;
      }

      .achivement-info {
        display: flex;
        width: 60%;
        justify-content: space-between;

        .reward {
          display: flex;
          align-items: center;
          gap: 9px;

          p {
            margin: 0;
            font-family: "Bai Jamjuree";
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.8px;
            color: #ffffff;
            text-transform: uppercase;
          }
        }

        .achivement-progress {
          display: flex;
          gap: 31px;
          margin: 0 20px 0 0;
          align-items: center;

          .span-container {
            display: flex;
            justify-content: center;
            background: transparent
              linear-gradient(188deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
              padding-box;
            border: 1px solid #377b5e;
            border-radius: 8px;
            padding: 10px 0;
            min-width: 170px;

            span {
              font-family: "Bai Jamjuree";
              font-weight: bold;
              font-size: 16px;
              letter-spacing: 0.8px;
              color: #39ffa9;
              text-transform: uppercase;
            }
          }

          .status {
            min-width: 63px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #ffffff;
              border: 1px solid #6b6152;
              padding: 5px 10px;
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }
      }

      .completed-on {
        display: none;
        font-family: "Bai Jamjuree";
        font-size: 10px;
        color: #ffffff;
        margin: 15px 0 0 0;
      }
    }
  }
}

@media (max-width: 1120px) {
  .content {
    .achivement-item {
      .achivement-content {
        h4 {
          margin: 0 0 0 20px !important;
        }

        .achivement-info {
          .achivement-progress {
            .span-container {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .content {
    .achivement-item {
      .achivement-content {
        .achivement-info {
          .achivement-progress {
            .span-container {
              min-width: 120px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1040px) {
  .content {
    .achivement-item {
      .achivement-content {
        .achivement-info {
          width: 55%;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .content {
    .achivement-item {
      .achivement-content {
        .achivement-info {
          .achivement-progress {
            gap: 10px;
            margin: 0 20px 0 0;
          }
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .header {
    flex-direction: column;
    gap: 7px;
  }
}

@media (max-width: 960px) {
  .content {
    .achivement-item {
      .achivement-content {
        flex-direction: column;
        margin: 0 0 0 26px;
        align-items: normal;

        h4 {
          margin: 0 !important;
        }

        .achivement-info {
          align-items: center;
          margin: 5px 0 0 0;
          gap: 20px;
          justify-content: start;
          width: 100%;

          .achivement-progress {
            margin: 0;

            .span-container {
              padding: 0 5px;
              min-width: 90px;
              width: fit-content;
              height: 20px;
              align-items: center;

              span {
                font-size: 10px;
              }
            }

            .status {
              min-width: 0;
              margin: 0 10px 0 0;
            }

            img {
              width: 15px;
            }
          }
        }

        .completed-on {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: 783px) {
  .header {
    padding: 0 20px;

    .progress {
      min-width: 0;
    }
  }
  .content {
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .content {
    .achivement-item {
      .achivement-content {
        h4 {
          font-size: 16px;
        }

        .achivement-info {
          gap: 10px;
          .reward {
            p {
              font-size: 10px;
            }

            img {
              width: 13px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .content {
    .achivement-item {
      .achivement-content {
        h4 {
          font-size: 16px;
        }

        .achivement-info {
          .reward {
            gap: 5px;
          }

          .achivement-progress {
            .status {
              span {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 416px) {
  .content {
    .achivement-item {
      .achivement-content {
        margin: 0 0 0 10px;
      }
    }
  }
}
