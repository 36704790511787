.skills-section {
  display: flex;
  background: url("../../../../public/assets/images/backgrounds/skills-section-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1080px;
  height: 100vh;
  margin: -75px 0 0 0;

  .skills-section-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  h3 {
    text-align: center;
    padding: 0 0 0 0;
    font-family: "Bai Jamjuree";
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.48px;
    color: #ffffff;
    text-transform: uppercase;
  }

  p {
    text-align: center;
    font-family: "Bai Jamjuree";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    max-width: 1206px;
    margin: 42px 0 0 0;
  }

  .items-container {
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 13px;

    .bottom {
      margin: 70px 0 0 0;
    }

    img {
      width: 267px;
      height: 426px;
      overflow: hidden;
    }

    /* .card-container {
      position: relative;
      overflow: hidden;
    } */

    .card-01 {
      background-image: url("../../../../public/assets/images/items/arkanes/card-back.png");
    }
    .card-02 {
      background-image: url("../../../../public/assets/images/items/arkanes/arkane-01.png");
    }
    .card-03 {
      background-image: url("../../../../public/assets/images/items/arkanes/arkane-02.png");
    }
    .card-04 {
      background-image: url("../../../../public/assets/images/items/arkanes/arkane-03.png");
    }
    .card-05 {
      background-image: url("../../../../public/assets/images/items/arkanes/arkane-04.png");
    }
    .card-06 {
      background-image: url("../../../../public/assets/images/items/arkanes/arkane-05.png");
    }

    .card {
      background-repeat: no-repeat;
      background-size: contain;
      width: 267px;
      height: 426px;
      overflow: hidden;
      display: inline-block;
    }

    .card::after {
      position: absolute;
      top: -50%;
      left: -60%;
      width: 20%;
      height: 200%;
      content: "";
      opacity: 0;
      transform: rotate(30deg);
      background: rgba(255, 255, 255, 0.13);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .card:hover:after {
      opacity: 1;
      left: 440px !important;
      transition-property: left, top, opacity;
      transition-duration: 0.8s, 0.8s, 0.15s;
      transition-timing-function: ease;
    }

    /* Active state */
    .card:active:after {
      opacity: 0;
    }
  }

  .play-button {
    margin: 40px 0 0 0;
  }
}

@media (min-width: 1920px) {
  .skills-section {
    height: 180vh;

    p {
      margin: 80px 0 0 0;
    }

    .items-container {
      margin: 100px 0 0 0;
    }
  }
}

@media (max-width: 1672px) {
  .skills-section {
    .items-container {
      img {
        width: 230px;
        height: 390px;
      }
    }
  }
}

@media (max-width: 1452px) {
  .skills-section {
    .items-container {
      img {
        width: 200px;
        height: 350px;
      }
    }
  }
}

@media (max-width: 1276px) {
  .skills-section {
    .items-container {
      img {
        width: 173px;
        height: 276px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .skills-section {
    .items-container {
      .bottom {
        margin: 0;
      }

      div > div {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 991px) {
  .skills-section {
    background-size: cover;
    min-height: 1500px;
    padding: 100px 0 0 0;
  }
}

@media (max-width: 600px) {
  .skills-section {
    p {
      max-width: 354px;
    }
  }
}
