.sliders-section {
  .champions {
    background: url("../../../../public/assets/images/backgrounds/bg-wall-character.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 1080px;

    display: flex;
    flex-direction: column;

    .champions-section-header {
      margin: 200px 0 0 0 !important;

      text-align: center;
      font-family: "Bai Jamjuree";
      font-size: 48px;
      font-weight: bold;
      letter-spacing: -0.48px;
      color: #ffffff;
      text-transform: uppercase;
    }

    img {
      max-width: 100px;
      max-height: 12px;
      align-self: center;
    }

    .champions-section-text {
      margin: 25px 0 0 0;

      text-align: center;
      font-family: "Bai Jamjuree";
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .champions-slider {
      margin: 52px 0 0 0;
    }
  }

  .items {
    background: url("../../../../public/assets/images/backgrounds/bg-wall-items.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 1035px;

    display: flex;
    flex-direction: column;

    padding: 130px 0 0 0;

    ul {
      display: flex;
      padding: 0 !important;
      flex-wrap: wrap;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--item-selector-inactive);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 229px;
        height: 78.5px;
        list-style: none;
        font-size: 18px;
        font-weight: bold;
        font-family: "Bai Jamjuree";
        letter-spacing: 0.9px;
        color: #ffe798;
        text-transform: uppercase;
        cursor: pointer;
      }

      .active,
      li:hover {
        background: var(--item-selector-active);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .items-slider {
      margin: 60px 0 0 0;
      padding: 0 127px;
    }
  }

  .play-button {
    margin: 80px 0 0 0;
  }
}

@media (max-width: 1640px) {
  .sliders-section {
    .items {
      .items-slider {
        padding: 0 50px 0 60px !important;
      }
    }
  }
}

@media (max-width: 1143px) {
  .sliders-section {
    .items {
      .items-slider {
        padding: 0 30px 0 40px !important;
      }
    }
  }
}

@media (max-width: 610px) {
  .sliders-section {
    .items {
      .items-slider {
        padding: 0 0 0 10px !important;
      }
    }
  }
}

@media (max-width: 460px) {
  .sliders-section {
    .items {
      ul {
        li {
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .sliders-section {
    .items {
      ul {
        li {
          width: 180px;
          height: 50px;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .sliders-section {
    .items {
      ul {
        li {
          width: 150px;
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .sliders-section {
    .items {
      ul {
        li {
          width: 150px;
          font-size: 15px;
        }
      }
    }
  }
}
