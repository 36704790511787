h2 {
  color: #ffffff;
  font-size: 20px;
}

.inventory {
  background: #0f0c08 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  padding: 29px 36px 0 29px;
  width: 100%;
  display: flex;
  flex-direction: column;

  h4 {
    font-family: "Bai Jamjuree";
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1px;
    color: #ffdd8b;
    text-transform: uppercase;
  }

  .inventory-section {
    margin: 42px 0 0 0;

    .inventory-section-header {
      display: flex;
      justify-content: space-between;

      .inventory-section-title {
        font-family: "Bai Jamjuree";
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #ffdd8b;
      }

      a {
        background: #ffdd8b4d 0% 0% no-repeat padding-box;
        border: 1px solid #77694b;
        border-radius: 4px;

        font-family: "Bai Jamjuree";
        font-size: 8px;
        font-weight: 600;
        letter-spacing: 0.8px;
        color: #ffdd8b;
        text-transform: uppercase;
        text-decoration: none;

        padding: 5px 20px;
        height: fit-content;
      }
    }

    .inventory-content {
      display: flex;
      gap: 9px;
      min-height: 100px;

      .inventory-item {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 123px;

        .img-container-pet,
        .img-container-egg {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background: url("../../../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
          background-position: center;
          background-size: cover;
          height: 100%;
          min-height: 120px;

          img {
            width: 80%;
            height: auto;
          }

          .for-sale,
          .banned {
            position: absolute;
            width: auto;
            height: 90%;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .img-container-egg {
          img {
            width: 40%;
            height: auto;
          }
        }

        span {
          font-family: "Bai Jamjuree";
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 0.5px;
          color: #ffffff;
          text-transform: uppercase;
        }
      }
    }
  }

  .breeding {
    display: flex;
    justify-content: center;
    text-decoration: none;

    button {
      margin: 33px 0 0 0;
      max-width: 261px;
      width: 100%;
      align-self: center;
      text-transform: uppercase;
      background-color: transparent;
      border: none;
      height: 50px;
      font-family: "Bai Jamjuree";
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.8px;
      color: #ffdd8b;
    }
  }
}

@media (max-width: 1859px) {
  .inventory {
    .breeding {
      margin: 33px 0 33px 0;
    }
  }
}

@media (max-width: 1290px) {
  .inventory {
    .inventory-section {
      .inventory-content {
        min-width: 320px;
      }
    }
  }
}

@media (max-width: 1088px) {
  .inventory {
    .inventory-section {
      .inventory-content {
        justify-content: center;
        min-width: 100%;

        .inventory-item {
          width: 100%;
          height: 100%;

          .img-container-egg {
            height: 200px;

            img {
              width: 30%;
              height: auto;
              margin: 20px 0 0 0;
            }
          }

          .img-container-pet {
            height: 200px;
            img {
              width: 80%;
              height: auto;

              margin: 20px 0 0 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 930px) {
  .inventory {
    .inventory-section {
      .inventory-content {
        .inventory-item {
          .img-container-egg,
          .img-container-pet {
            height: 150px;
          }
        }
      }
    }
  }
}

@media (max-width: 472px) {
  .inventory {
    .inventory-section {
      .inventory-content {
        flex-wrap: wrap;
        justify-content: center;
        min-width: 100%;

        .inventory-item {
          width: 100%;
          height: 100%;

          .img-container-egg {
            height: 219px;

            img {
              width: 20%;
              height: auto;
              margin: 20px 0 0 0;
            }
          }

          .img-container-pet {
            height: 219px;
            img {
              width: 55%;
              height: auto;

              margin: 20px 0 0 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .inventory {
    .inventory-section {
      .inventory-content {
        flex-wrap: wrap;
        justify-content: center;
        min-width: 100%;

        .inventory-item {
          width: 100%;
          height: 100%;

          .img-container-egg {
            height: 150px;

            img {
              width: 25%;
              height: auto;
              margin: 20px 0 0 0;
            }
          }
        }
      }
    }
  }
}
