.partinersSection {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .partners-title {
    margin: 87px 0 20px 0 !important;
    text-align: center;
    font: normal normal bold 48px/24px Bai Jamjuree;
    letter-spacing: -0.48px;
    color: #0f0c08;
    text-transform: uppercase;
  }

  .partners-text {
    margin: 31px 0 0 0 !important;
    text-align: center;
    font: normal normal 600 20px/30px Bai Jamjuree;
    letter-spacing: 0px;
    color: #0f0c08;
    max-width: 1428px;
  }

  .partners-img,
  .partners-img-mobile {
    max-width: 1129px;
    width: 100%;
    margin: 66px 0 100px 0;
    background-repeat: no-repeat;
    padding: 0 40px;
  }

  .partners-img-mobile {
    display: none;
  }
}

@media (max-width: 540px) {
  .partinersSection {
    .partners-title {
      line-height: 50px;
    }

    .partners-img-mobile {
      display: flex;
    }

    .partners-img {
      display: none;
    }
  }
}
