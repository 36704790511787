@import "../node_modules/@rainbow-me/rainbowkit/dist/index.css";

:root {
  /* FEATURES IMG */
  --feature01-desktop-img: url("../public/assets/images/features/game-features01.png");
  --feature01-mobile-img: url("../public/assets/images/features/game-features01-mobile.png");

  --feature02-desktop-img: url("../public/assets/images/features/game-features02.png");
  --feature02-mobile-img: url("../public/assets/images/features/game-features02-mobile.png");

  --feature03-desktop-img: url("../public/assets/images/features/game-features03.png");
  --feature03-mobile-img: url("../public/assets/images/features/game-features03-mobile.png");

  --feature04-desktop-img: url("../public/assets/images/features/game-features04.png");
  --feature04-mobile-img: url("../public/assets/images/features/game-features04-mobile.png");

  /* IZARIAN CHAMPION IMG */
  --izarian-desktop-img: url("../public/assets/images/champions/portrate-izarian.png");
  --izarian-mobile-img: url("../public/assets/images/champions/portrate-izarian-mobile.png");

  /* BERSERKER CHAMPION IMG */
  --berserker-desktop-img: url("../public/assets/images/champions/portrate-berserker.png");
  --berserker-mobile-img: url("../public/assets/images/champions/portrate-berserker-mobile.png");

  /* SOULHUNTER CHAMPION IMG */
  --soulhunter-desktop-img: url("../public/assets/images/champions/portrate-soulhunter.png");
  --soulhunter-mobile-img: url("../public/assets/images/champions/portrate-soulhunter-mobile.png");

  /* ALCHEMIST CHAMPION IMG */
  --alchemist-desktop-img: url("../public/assets/images/champions/portrate-alchemist.png");
  --alchemist-mobile-img: url("../public/assets/images/champions/portrate-alchemist-mobile.png");

  /* WASTELLER CHAMPION IMG */
  --wasteller-desktop-img: url("../public/assets/images/champions/portrate-wasteller.png");
  --wasteller-mobile-img: url("../public/assets/images/champions/portrate-wasteller-mobile.png");

  /* BACKGROUND-CHAMPION-CONTENT */
  --background-champion-content: url("../public/assets/images/champions/champion-content.svg");

  /* ITEMS SLIDER SELCTORS */
  --item-selector-inactive: url("../public/assets/images/items/items-slider-selector-inactive.svg");
  --item-selector-active: url("../public/assets/images/items/items-slider-selector-active.svg");
}

#root {
  background: transparent linear-gradient(180deg, #201a14 0%, #443928 100%) 0%
    0% no-repeat padding-box;
}

/* SLICK SLIDER STYLES */

.slick-list {
  height: 750px;
}

.slick-slide {
  div {
    justify-content: center;
    display: flex;
  }
}

.marketplace-container {
  .slick-slider {
    .slick-dots {
      bottom: 4% !important;

      li {
        background: #fff;
        border: none;
      }
      .slick-active {
        background: #39ffa9;
      }
    }
  }
}
.slick-dots {
  bottom: -8% !important;

  li {
    background: #39ffa96e;
    border: 1px solid #39ffa9;
    width: 9px !important;
    height: 9px !important;
    border-radius: 100%;

    button {
      opacity: 0;
    }
  }

  .slick-active {
    background: #39ffa9;
  }
}

@media (max-width: 1200px) {
  .slick-slide {
    div {
      display: flex;
      justify-content: center;
    }
  }

  .slick-dots {
    bottom: 5% !important;
  }
}

@media (max-width: 850px) {
  .slick-dots {
    bottom: 17% !important;
  }
}

@media (max-width: 750px) {
  .slick-dots {
    bottom: 12% !important;
  }
}
/* END SLICK SLIDERS STYLES */

#root {
  background-color: #d8d1ca;
  overflow-x: hidden;
}

.arker-container {
  max-width: 1920px;
  width: 100%;
  margin: auto;
}

.marketplace-container {
  max-width: 1920px;
  width: 100%;
  margin: auto;
}

.link-inactive {
  text-decoration: none;
}

.link-active {
  color: #39ffa9;
}

.simple-button,
.button-green,
.button-green {
  border: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  font-family: "Bai Jamjuree";
  color: #131b17;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 1.05px;
  align-self: center;
  align-items: center;

  a {
    text-decoration: none;
    color: #131b17;
  }
}
.simple-button {
  background-image: url("../public/assets/images/buttons/simple-button.svg");
}

.button-green {
  background-image: url("../public/assets/images/buttons/button-green.png");
  -webkit-transition: background-image 0.4s ease-in-out;
  transition: background-image 0.4s ease-in-out;

  &:hover {
    background-image: url("../public/assets/images/buttons/button-green-effect.png");
  }
}

.button-green-effect {
  background-image: url("../public/assets/images/buttons/button-green-effect.png");
}

.see-detail-button {
  background-image: url("../public/assets/images/buttons/see-detail.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.load-more-button {
  background-image: url("../public/assets/images/buttons/load-more.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.breeding-button {
}

.button-green-big {
  width: 340px;
  height: 120px;
  font-size: 28px;
  letter-spacing: 1.4px;
  a {
    text-decoration: none;
    color: #131b17;
  }
}

.button-green-small {
  width: 280px;
  height: 100px;
  a {
    text-decoration: none;
    color: #131b17;
  }
}

.divider-g {
  width: 119px;
}

.divider-s {
  width: 100px;
}

.font-normal {
  text-transform: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

button {
  padding: 0;
}

.center {
  display: flex;
  justify-content: center;
}
