.loading-container {
  display: flex;
  justify-content: center;
  margin: 20px 0 40px 0;

  h1 {
    color: #ffffff;
  }
}

.marketplace-content {
  padding: 21px 10px 100px 10px;
  background-color: #d5d0c9;

  .marketplace-content-container {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .sidebar {
      .filters {
        max-width: 222px;
        background-color: #30291b;
        border-radius: 8px;

        /* ACCORDION */
        .tab {
          background-color: #30291b !important;
          border-bottom: 0.5px solid #ffdd8bb3;

          label {
            color: #ffdd8b;
            text-transform: uppercase;

            img {
              width: 12px;
              transition: all 0.35s;
            }
          }
        }

        .tab:last-child {
          border-bottom: 0;
        }

        .tab input {
          position: absolute;
          opacity: 0;
          z-index: -1;
        }
        .tab__content {
          max-height: 0;
          overflow: hidden;
          transition: all 0.35s;

          ul {
            padding: 5px 0 0 17px;
            margin: 0;

            li {
              cursor: pointer;
              padding: 10px 17px 0 0;
              list-style: none;

              label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                cursor: pointer;
                font-family: "Bai Jamjuree";
                font-size: 14px;
                letter-spacing: 0.7px;
                color: #ffffff;
                text-transform: capitalize;
              }

              .checkbox_square {
                display: flex;
                width: 14px;
                height: 14px;
                border: 1px solid #ffdd8b;

                img {
                  display: none;
                }
              }
            }

            li input:checked ~ .checkbox_square {
              img {
                display: flex;
              }
            }
          }
        }
        .tab input:checked ~ .tab__content {
          border-top: 0.5px solid #ffdd8bb3;
          max-height: 10rem;
          margin: 0 0 15px 0;
        }

        /* Visual styles */
        .accordion {
          color: var(--theme);
          border: 2px solid;
          border-radius: 0.5rem;
          overflow: hidden;
        }
        .tab__label,
        .tab__close {
          display: flex;
          color: white;
          background: var(--theme);
          cursor: pointer;
        }
        .tab__label {
          justify-content: space-between;
          padding: 1rem;
        }
        .tab input:checked + .tab__label img {
          transform: rotate(180deg);
        }
        .tab__content p {
          margin: 0;
          padding: 1rem;
        }
        /* END ACCORDION */
      }

      .downloads {
        margin: 20px 0 0 0;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 222px;
        background: transparent
          linear-gradient(182deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #377b5e;
        border-radius: 8px;

        .title {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 0 0 10px 0;

          img {
            width: 30px;
            border-radius: 100%;
          }

          h4 {
            font-size: 14px;
            font-weight: 600;
            font-family: "Bai Jamjuree";
            letter-spacing: 0.7px;
            color: #37f5a2;
            text-transform: uppercase;
          }
        }

        .platform-button {
          margin: 5px 0 0 0;
          text-decoration: none;
          display: flex;
          align-items: center;
          padding: 10px;
          background: transparent
            linear-gradient(180deg, #131b17b3 0%, #1e533eb3 100%) 0% 0%
            no-repeat padding-box;
          border: 1px solid #377b5e;
          border-radius: 6px;
          width: 131px;
          height: 44px;

          span {
            padding: 0 0 0 10px;
            text-align: left;
            font-size: 9px;
            font-family: "Bai Jamjuree";
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }
    }

    .no-items-available {
      position: absolute;
      left: 50%;
    }
    .content > div {
      gap: 20px;
      display: flex;
      justify-content: center;
    }

    .content {
      gap: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      .load-more {
        margin: 20px 0 80px 0;
        align-self: center;
        background-color: transparent;
        border: none;
        width: 100%;
        max-width: 226px;
        min-height: 48px;

        text-transform: uppercase;
        font-family: "Bai Jamjuree";
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 1.6px;
        color: #443928;
      }

      .recommended-items {
        gap: 20px;
        display: flex;
        justify-content: center;

        .title {
          background: #5e523b4d 0% 0% no-repeat padding-box;
          border-radius: 4px 4px 0px 0px;
          width: 100%;
          min-height: 42px;
          display: flex;
          align-items: center;
          padding: 10px 0 10px 20px;

          span {
            font-weight: bold;
            font-size: 20px;
            font-family: "Bai Jamjuree";
            letter-spacing: 1px;
            color: #443928;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@media (max-width: 880px) {
  .marketplace-content {
    .marketplace-content-container {
      .content {
        gap: 10px;
      }
    }
  }
}

@media (max-width: 790px) {
  .marketplace-content {
    .marketplace-content-container {
      gap: 10px;
    }
  }
}

@media (max-width: 600px) {
  .marketplace-content {
    .marketplace-content-container {
      flex-direction: column;
      .sidebar {
        .filters {
          width: 100%;
          min-width: 100%;
        }

        .downloads {
          display: none;
        }
      }

      .no-items-available {
        position: relative;
        left: 0;
        align-self: center;
      }

      .content {
        padding: 15px;
      }
    }
  }
}
