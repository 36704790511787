.table {
  width: 100%;
  margin: 12px 0 0 0;
  border: 1px solid #377b5e;
  border-radius: 8px;
  color: #ffffff;
  font-family: "Bai Jamjuree";

  border-spacing: 0;
  border-collapse: separate;
  border-radius: 8px;

  thead,
  ul {
    background: #38fea84d 0% 0% no-repeat padding-box;
    border-radius: 8px 0 0 0;
    padding: 5px 15px;
    border-spacing: 0;
    border-collapse: separate;

    tr {
      th {
        padding: 5px 10px;
      }

      th:first-child {
        border-radius: 7px 0 0 0;
      }
      th:last-child {
        border-radius: 0 7px 0 0;
      }
    }
  }

  tbody {
    background: transparent linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;

    .odd {
      background: #38fea826 0% 0% no-repeat padding-box;
    }

    tr {
      td {
        font-family: "Bai Jamjuree";
        font-size: 13px;
        letter-spacing: 0.6px;
        padding: 5px 10px;
      }
    }

    tr:last-child {
      td:last-child {
        border-radius: 0 0 8px 0;
      }
      td:first-child {
        border-radius: 0 0 0 8px;
      }
    }
  }

  .p-container {
    display: flex;
    flex-direction: column;

    p {
      align-self: center;
      margin: 10px 0 30px 0;

      font-family: "Bai Jamjuree";
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.7px;
      color: #ffffff;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 0;

    li {
      list-style: none;
      font-family: "Bai Jamjuree";
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.7px;
      color: #38fea8;
    }
  }

  p {
    align-self: center;
    margin: 10px 0 30px 0;

    font-family: "Bai Jamjuree";
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    color: #ffffff;
  }
}
