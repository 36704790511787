.parents-info {
  background: #0f0c0899 0% 0% no-repeat padding-box;
  border: 1px solid #6b6152;
  border-radius: 8px;
  width: 100%;
  max-width: 585px;
  display: flex;
  flex-direction: column;
  height: fit-content;

  .parents-info-header {
    height: 43px;
    width: 100%;
    background: #ffdd8b4d 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    padding: 0 20px;

    p {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 1px;
      color: #ffdd8b;
      text-transform: uppercase;
    }
  }

  .parents-container {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    margin: 40px 0 0 0;
    padding: 0 20px;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .parent {
      background: #30291b 0% 0% no-repeat padding-box;
      border: 1px solid #77694b;
      border-radius: 12px;
      max-width: 239px;
      width: 100%;
      min-height: 204px;
      padding: 0 10px;
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        gap: 10px;
      }

      div > span {
        font-family: "Bai Jamjuree";
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
      }

      .fheral-img-container {
        background: url("../../../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;

        .fheral-img {
          max-height: 132px;
        }
      }

      p {
        margin: 0;
        font-family: "Bai Jamjuree";
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #ffffff;
        text-transform: uppercase;
        align-self: center;
      }
    }

    .father > div > span {
      color: #7ee9ff;
    }

    .mother > div > span {
      color: #ffa5c7;
    }
  }

  .breed-info {
    background: #251f17 0% 0% no-repeat padding-box;
    border: 1px solid #77694b;
    border-radius: 8px;
    min-width: 502px;
    min-height: 120px;
    align-self: center;
    margin: 30px 20px 42px 20px;
    display: flex;
    flex-direction: column;

    .breed-info-header {
      background: #ffdd8b4d 0% 0% no-repeat padding-box;
      border-radius: 8px 8px 0px 0px;
      height: 39px;

      p {
        font-family: "Bai Jamjuree";
        font-weight: bold;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 0.8px;
        color: #ffdd8b;
        text-transform: uppercase;
        padding: 0 20px;
      }
    }

    span {
      font-family: "Bai Jamjuree";
      font-size: 14px;
      line-height: 40px;
      letter-spacing: 1.4px;
      color: #ffffff;
      text-transform: uppercase;
      padding: 5px 0 0 30px;
    }

    .no-cooldown {
      padding: 15px 0 0 30px;
    }

    .time-to-breed {
      padding: 0 0 10px 30px;
      line-height: 20px;
    }
  }
}

@media (max-width: 980px) {
  .parents-info {
    max-width: 50%;

    .parents-container {
      flex-direction: column;
      align-items: center;

      .parent {
        max-width: 300px;
      }
    }

    .breed-info {
      width: 100%;
      min-width: 0;
      max-width: 85%;
      margin: 30px 20px;
    }
  }
}

@media (max-width: 801px) {
  .parents-info {
    max-width: 100%;
  }
}
