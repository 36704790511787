.blog-post-card {
  max-width: 464px;
  background-color: #fff;
  border-radius: 12px;
  min-height: 600px;

  .blog-post-card-content {
    padding: 18px 46px;
    display: flex;
    flex-direction: column;

    p > figure {
      display: none;
    }

    .three-dots {
      margin: 0 !important;
      min-height: auto;
    }

    .read-more-container {
      width: 140px;
      height: 30px;
      display: flex;
      justify-content: center;
      justify-self: center;
    }

    .read-more {
      background-size: contain;
      font-size: 14px;
      align-self: center;
    }
  }

  .blog-post-img {
    width: 100%;
    height: 262px;
    border-radius: 12px 12px 0px 0px;
  }

  h4 {
    display: flex;
    gap: 4.5px;
    text-align: left;
    font: normal normal bold 28px/30px Bai Jamjuree;
    letter-spacing: -0.28px;
    color: #0f0c08;
    min-height: 60px;
    align-items: center;
  }

  p {
    margin: 6px 0 0 0;
    text-align: left;
    font: normal normal medium 16px/24px Bai Jamjuree;
    letter-spacing: 0px;
    color: #684e2b;
    min-height: 120px;
  }

  span {
    text-align: left;
    font: normal normal bold 16px/24px Bai Jamjuree;
    letter-spacing: 0px;
    color: #0f0c08;
  }

  .blog-post-footer {
    position: relative;
    margin: 26px 0 0 0;
    display: flex;
    gap: 22px;

    span {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .share {
    position: absolute;
    right: 0;
    width: 21px;
    height: 25px;
  }
  .separator {
    margin: 4px 0 0 0;
    width: 5px;
    height: 54px;
    background: #38fea8 0% 0% no-repeat padding-box;
    border-radius: 7px;
  }

  .heart {
    width: 16px;
    height: 14px;
  }

  .minus {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 1471px) {
  .blog-post-card {
    max-width: 420px;
  }
}

@media (max-width: 1343px) {
  .blog-post-card {
    max-width: 380px;
    .blog-post-img {
      height: auto;
    }
  }
}

@media (max-width: 1223px) {
  .blog-post-card {
    max-width: 350px;

    .blog-post-img {
      height: auto;
    }

    h4 {
      font-size: 26px;
    }
    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 1129px) {
  .blog-post-card {
    max-width: 354px;

    .blog-post-img {
      height: auto;
    }

    h4 {
      font-size: 26px;
    }
    p {
      font-size: 16px;
    }
  }
}
