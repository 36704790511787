.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 50px 0;

  h1 {
    color: #ffffff;
    text-align: center;
    font-family: "Bai Jamjuree";
  }

  hr {
    width: 100px;
    border-top: 2px solid #ffdd8b;
  }

  .status-message {
    background: transparent
            linear-gradient(190deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #377b5e;
    color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    max-width: 500px;
    margin: 10px 0 0 0;
  }

  .error {
    border-color: #ff000033 !important;
    background: #ff000033 0% 0% no-repeat padding-box !important;
    color: #ff0000 !important;
  }
}
