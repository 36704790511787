.select-pet-item {
  display: flex;
  flex-direction: column;
  background: transparent linear-gradient(180deg, #201a14 0%, #443928 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 270px;

  .pet-img {
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 100%;
    border-radius: 8px 8px 0 0;

    img {
      border-radius: 8px 8px 0 0;
    }
  }

  p {
    margin: 10px 0 0 0;
    color: #ffffff;
    font-family: "Bai Jamjuree";
    font-size: 15px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: 0.9px;
  }

  button {
    margin: 0;
    width: 140px;
    height: 70px;
    font-family: "Bai Jamjuree";
    font-size: 15px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: 0.9px;
    color: #ffdd8b;
    text-transform: uppercase;
    border: 0;
    background-color: transparent;
  }
}
