.content {
  display: flex;
  gap: 20px;
  padding: 20px 123px;
  background-color: #d5d0c9;

  .sidebar > img {
    width: 100px;
    align-self: center;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    background: transparent linear-gradient(181deg, #131b17 0%, #1e533e 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #377b5e;
    border-radius: 8px;
    padding: 20px 15px;
    width: 100%;
    max-width: 220px;
    height: fit-content;

    h4 {
      font-family: "Bai Jamjuree";
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.8px;
      color: #ffffff;
      align-self: center;

      margin: 16px 0 0 0 !important;
    }

    .address-container {
      display: flex;
      margin: 9px 0 0 0;
      gap: 5px;
      align-self: center;
      background: #37f8a442 0% 0% no-repeat padding-box;
      border: 1px solid #377b5e;
      border-radius: 4px;
      padding: 3px 7px;
      cursor: pointer;
      width: fit-content;
      min-width: 110px;
      justify-content: center;

      span {
        font-family: "Bai Jamjuree";
        font-size: 11px;
        letter-spacing: 0.55px;
        color: #37f8a4;
      }
    }

    ul {
      padding: 0;
      margin: 30px 0 0 0;
      display: flex;
      flex-direction: column;

      li {
        cursor: pointer;
        list-style: none;
        color: #ffffff;
        font-family: "Bai Jamjuree";
        font-size: 14px;
        letter-spacing: 0.7px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-radius: 4px;

        div {
          width: 20px;
        }

        img {
          width: 13px;
        }

        .arkanes {
          width: 19px;
        }

        .skins {
          width: 16px;
        }

        .weapons {
          width: 16px;
        }

        .chests {
          width: 16px;
        }

        .relics {
          width: 16px;
        }

        .fheral {
          width: 18px;
        }

        .achivements {
          width: 18px;
          height: 17px;
        }

        .wishlist {
          width: 12px;
          height: 19px;
        }

        .community {
          width: 18px;
        }

        .arrow {
          width: 9px;
          transition: all ease-in-out 0.3s;
        }

        a {
          color: #ffffff;
          text-decoration: none;
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      li:hover,
      .active {
        background: #37f8a41a 0% 0% no-repeat padding-box;
      }

      .active {
        .arrow {
          transform: rotate(180deg);
        }
      }

      .fherals-dropdown {
        max-height: 0;
        overflow: hidden;
        transition: max-height ease-out 0.3s;

        ul {
          margin: 10px 0 0 0;
          padding: 0 0 0 10px;

          li {
            max-height: 35px;
          }

          li:hover,
          .active {
            background: #37f8a41a 0% 0% no-repeat padding-box;
          }

          li:before {
            content: ".";
            font-size: 3em;
            margin: -26px 0 0 0;
            color: #39ffa9;
          }
        }
      }

      .show-fherals-dropdown {
        max-height: 150px;
        transition: max-height ease-in 0.3s;
      }
    }
  }

  .outlet {
    width: 100%;
  }
}

.content > div {
  display: flex;
  gap: 20px;
}

@media (max-width: 1300px) {
  .content {
    padding: 20px 50px;
  }
}

@media (max-width: 1066px) {
  .content {
    .outlet {
      width: 75%;
    }
  }
}

@media (max-width: 954px) {
  .content {
    .outlet {
      width: 80%;
    }
  }
}

@media (max-width: 783px) {
  .content {
    padding: 0 0 20px 0;
    margin: 0;

    .sidebar {
      max-width: 100%;
      border-radius: 0;

      ul {
        padding: 0 0 0 64px;
        margin: 20px 0 0 0;
      }
    }

    .outlet {
      width: 100%;
    }
  }

  .content > div {
    flex-direction: column;
  }
}
