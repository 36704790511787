.slick-slider-item {
  width: auto !important;
  height: 466px;
  position: relative;

  img {
    width: auto;
    height: 100%;
  }
}

.slick-slider-item-content {
  position: absolute;
  bottom: 7%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
    font-family: "Bai Jamjuree";
    font-size: 18px;
    letter-spacing: 1.8px;
    color: #38fea8;
    text-transform: uppercase;
  }

  p {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: "Bai Jamjuree";
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #38fea8;
    text-transform: uppercase;
    max-width: 250px;
    min-height: 66px;
  }
}

@media (max-width: 1827px) {
  .slick-slider-item {
    height: 450px;
  }
}

@media (max-width: 1774px) {
  .slick-slider-item {
    height: 420px;
  }

  .slick-slider-item-content {
    bottom: 5.5%;

    p {
      max-width: 230px;
      font-size: 16px;
    }
  }
}

@media (max-width: 1672px) {
  .slick-slider-item {
    height: 400px;
  }

  .slick-slider-item-content {
    p {
      max-width: 230px;
      font-size: 16px;
    }
  }
}

@media (max-width: 1614px) {
  .slick-slider-item {
    height: 380px;
  }

  .slick-slider-item-content {
    bottom: 4%;

    p {
      max-width: 210px;
      font-size: 15px;
    }
  }
}

@media (max-width: 1520px) {
  .slick-slider-item {
    height: 380px;
  }

  .slick-slider-item-content {
    p {
      max-width: 210px;
      font-size: 15px;
    }
  }
}
