.check-item {
  display: flex;
  align-items: center;
  width: 467px;
  height: 42px;
  background-color: #938878b3;
  position: relative;
  margin: 0 0 0 20px;
  border-radius: 5px;

  img {
    width: 39px !important;
    height: 42px !important;
    position: absolute;
    left: -20px;
  }

  span {
    text-align: left;
    font-family: "Bai Jamjuree";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0f0c08;
    margin: 0 0 0 30px;
    line-height: 20px;
  }
}

@media (max-width: 1200px) {
  .check-item {
    width: 100%;
    max-width: 94%;
  }
}
