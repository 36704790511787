.marketplace-item-info-container {
  max-width: 1432px;
  margin: auto;
  padding: 0 20px;

  .breadcrumbs {
    display: flex;
    align-items: center;
    background: #ffdd8b33 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 0 17px;
    height: 30px;

    span {
      font-family: "Bai Jamjuree";
      font-size: 14px;
      letter-spacing: 0.7px;
      color: #ffdd8b;
    }
  }

  .top {
    margin: 10px 0 0 0;
    display: flex;
    gap: 20px;

    .image-container,
    .item-info {
      background: #0f0c0899 0% 0% no-repeat padding-box;
      border: 1px solid #6b6152;
      border-radius: 6px;
    }

    .image-container {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 600px;
      max-width: 826px;

      .wishlist-icon,
      .fullscreen-icon {
        position: absolute;
        top: 15px;
        width: 45px;
        cursor: pointer;
      }
      .wishlist-icon {
        left: 15px;
        z-index: 1;
      }

      .fullscreen-icon {
        right: 15px;
        z-index: 1;
      }

      .item-image-container {
        position: relative;
        background-image: url("../../../../public/assets/images/marketplace/backgrounds/fheral-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .item-image {
          height: 300px;
          width: auto;
        }

        .item-image-chest {
          height: auto;
          margin: 160px 0 0 0;
          width: 30rem;
        }

        .item-title {
          position: absolute;
          bottom: 70px;
          width: 100%;
          display: flex;
          justify-content: center;
          min-height: 90px;
          align-items: center;

          p {
            max-width: 246px;
            font-family: "Bai Jamjuree";
            font-weight: 600;
            font-size: 24px;
            letter-spacing: 0px;
            color: #38fea8;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }

    .image-container-chest {
      max-width: 1069px;
      background: url("../../../../public/assets/images/marketplace/backgrounds/common-bg.png");
      background-size: cover;
      background-position: center 60%;
      background-repeat: no-repeat;
    }

    .item-info {
      position: relative;
      width: 100%;
      max-width: 585px;
      height: 600px;
      padding: 30px 49px;

      h1 {
        font-family: "Bai Jamjuree";
        font-weight: bold;
        font-size: 26px;
        letter-spacing: 1.3px;
        color: #ffffff;
        text-transform: uppercase;
      }

      .available {
        margin: 20px 0 0 0;
        display: block;
        background: #ffdd8b1a 0% 0% no-repeat padding-box;
        border-radius: 4px;
        width: 100%;
        text-align: center;
        height: 39px;
        line-height: 40px;
        font-family: "Bai Jamjuree";
        font-size: 16px;
        letter-spacing: 0.8px;
        color: #ffdd8b99;
      }

      .current-price {
        margin: 70px 0 0 0;

        span {
          font-family: "Bai Jamjuree";
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0px;
          color: #ffffff80;
        }

        .price {
          display: flex;
          gap: 15px;
          img {
            width: 23.4px;
          }

          span {
            font-family: "Bai Jamjuree";
            font-weight: bold;
            font-size: 26px;
            line-height: 33px;
            letter-spacing: 2.6px;
            color: #ffffff;
            text-transform: uppercase;
          }
        }
      }

      .buttons-container {
        margin: 36px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          border: 0;
          background-color: transparent;
        }

        .buy-button {
          width: 345px;
          height: 110px;
          cursor: pointer;
        }

        .connect-wallet-button {
          width: 345px;
          height: 110px;
          cursor: pointer;
          font-size: 18px;
        }

        .disabled:hover {
          background-image: url("../../../../public/assets/images/buttons/button-green.png");
          cursor: not-allowed;
        }

        .add-wishlist-button {
          width: 290px;
          height: 70px;
          font-family: "Bai Jamjuree";
          font-size: 18px;
          line-height: 23px;
          font-weight: 600;
          letter-spacing: 0.9px;
          color: #ffdd8b;
          text-transform: uppercase;
        }

        .inventory-buttons-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          align-content: center;

          .sell-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 8px;
            padding: 30px 0 20px 0;
            margin: 0 0 20px 0;
            border: 1px solid #ffdd8b80;

            input {
              border: 1px solid #6b6152;
              border-radius: 8px;
              height: 54px;
              padding: 0 10px 0 20px;
              color: #fff;
              background-color: #19150f;
              min-width: 232px;
              color-scheme: dark;

              &:focus {
                outline: 2px solid #6b6152;
              }
            }

            .amount-input {
              padding: 0 10px 0 45px;
              background-color: #0f0c08;
              background: url("../../../../public/assets/images/icons/arker-icon.svg")
                no-repeat scroll 15px;
            }
          }
        }
      }

      .owned {
        position: absolute;
        bottom: 30px;
        left: 49px;
        margin: 0;
        font-family: "Bai Jamjuree";
        font-size: 16px;
        letter-spacing: 0px;
        color: #ffffff;

        a {
          color: #38fea8;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .bottom {
    margin: 25px 0 36px 0;
    display: flex;
    gap: 20px;

    .item-description-container {
      width: 100%;
      max-width: 826px;
      height: fit-content;

      .item-description,
      .item-activity {
        background: transparent
          linear-gradient(180deg, #131b17 0%, #1e533e 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #377b5e;
        border-radius: 8px;

        .description-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #38fea84d 0% 0% no-repeat padding-box;
          border-radius: 8px 8px 0px 0px;
          width: 100%;
          height: 43px;
          padding: 0 20px;
          cursor: pointer;

          span {
            font-family: "Bai Jamjuree";
            font-weight: 600;
            line-height: 40px;
            font-size: 20px;
            letter-spacing: 1px;
            color: #38fea8;
            text-transform: uppercase;
          }

          .header-attributes {
            margin: 0 0 0 140px;
          }

          img {
            width: 15px;
            transition: all ease-in-out 0.3s;
          }

          .rotate {
            transform: rotate(180deg);
            transition: all ease-in-out 0.3s;
          }
        }

        .description-content {
          display: flex;
          justify-content: space-evenly;
          max-height: 0;
          overflow: hidden;
          transition: all ease-out 0.3s;

          .info {
            /* width: 100%; */
            min-width: 267px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .info-item {
              display: flex;
              width: 100%;

              .info-item-name,
              .info-item-value {
                width: 50%;
                font-family: "Bai Jamjuree";
                font-size: 14px;
                line-height: 26px;
                letter-spacing: 0.7px;
                color: #38fea8;
                text-transform: uppercase;
                margin: 0;
              }

              .info-item-name {
                text-align: end;
              }
              .info-item-value {
                color: #fff;
                margin: 0 0 0 15px;
              }
            }
          }

          .description-text {
            max-width: 391px;
            display: flex;
            align-items: center;

            p {
              font-family: "Bai Jamjuree";
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0px;
              color: #ffffff;
            }
          }

          .description-text-chest {
            max-width: 100%;
          }

          .attributes-header-mobile {
            display: none;
            background: #38fea84d 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0px 0px;
            width: 100%;
            height: 43px;
            padding: 0 20px;

            span {
              font-family: "Bai Jamjuree";
              font-weight: 600;
              line-height: 40px;
              font-size: 20px;
              letter-spacing: 1px;
              color: #38fea8;
              text-transform: uppercase;
            }
          }

          .attributes {
            .attributes-item {
              display: flex;
              align-items: center;
              gap: 20px;

              span {
                font-family: "Bai Jamjuree";
                font-size: 16px;
                line-height: 42px;
                letter-spacing: 0.8px;
                color: #38fea8;
                text-transform: uppercase;
                text-align: end;
                min-width: 69px;
              }

              .attributes-item-num {
                background-size: contain !important;
                background-repeat: no-repeat !important;
                width: 40px;
                height: 40px;
                background-position: center !important;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                  font-family: "Bai Jamjuree";
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 20px;
                  letter-spacing: 0.8px;
                  min-width: 0;
                }
              }

              .cost {
                background: url("../../../../public/assets/images/marketplace/icons/cust-element.svg");
              }

              .mult {
                background: url("../../../../public/assets/images/marketplace/icons/mult-element.svg");
                span {
                  color: #ffe563;
                }
              }

              .attack {
                background: url("../../../../public/assets/images/marketplace/icons/attack-element.svg");
                span {
                  color: #ff875d;
                }
              }
            }
          }
        }

        .description-content-chest {
          height: 80%;
          justify-content: center;
        }

        .description-content-show {
          padding: 35px 0 20px 0;
          max-height: 1500px;
          transition: all ease-in 0.3s;
        }

        .stats {
          display: flex;
          column-gap: 35px;
          flex-wrap: wrap;
          padding: 0 20px 0 20px;
          max-height: 0;
          overflow: hidden;
          transition: all ease-out 0.3s;

          .attack,
          .life,
          .power,
          .agility,
          .energy {
            display: flex;
            margin: 7px 0 0 0;
            gap: 9px;

            span {
              font-family: "Bai Jamjuree";
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0px;
              color: #ffffff;
              min-width: 85px;
            }
          }
        }

        .stats-show {
          padding: 13px 20px 25px 20px;
          max-height: 1000px;
          transition: all ease-in 0.3s;
        }

        .skill {
          display: flex;
          justify-content: space-between;
          padding: 0 40px 0 40px;
          max-height: 0;
          overflow: hidden;
          transition: all ease-out 0.3s;
          margin: 20px 0 0 0;
          gap: 20px;

          .skills-content {
            display: flex;
            gap: 20px;
            align-self: center;
            margin: 0 0 0 30px;

            .skills-content-item {
              display: flex;
              flex-direction: column;
              align-items: center;

              img {
                width: 63px;
                height: 63px;
              }

              span {
                font-family: "Bai Jamjuree";
                font-size: 12px;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.6px;
                color: #ffffff;
                text-transform: uppercase;
              }
            }
          }

          .skills-description {
            max-width: 391px;

            span {
              font-family: "Bai Jamjuree";
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0px;
              color: #ffffff;
            }
          }
        }

        .skill-show {
          padding: 13px 20px 25px 20px;
          max-height: 1000px;
          transition: all ease-in 0.3s;
        }
      }

      .item-activity {
        margin: 20px 0 0 0;

        .item-activity-content {
          height: 0;
          overflow: scroll;
          transition: all ease-out 0.3s;
          display: flex;
          flex-direction: column;
          -ms-overflow-style: none;
          scrollbar-width: none;

          .item-activity-table {
            thead {
              tr {
                th {
                  padding: 5px 15px;
                  list-style: none;
                  font-family: "Bai Jamjuree";
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 26px;
                  letter-spacing: 0.7px;
                  color: #38fea8;
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 5px 15px;
                  font-family: "Bai Jamjuree";
                  font-size: 14px;
                  line-height: 18px;
                  letter-spacing: 0.7px;
                  color: #ffffff;
                }
              }
            }
          }

          ul {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            margin: 0;

            li {
              list-style: none;
              font-family: "Bai Jamjuree";
              font-weight: 600;
              font-size: 14px;
              line-height: 26px;
              letter-spacing: 0.7px;
              color: #38fea8;
            }
          }

          p {
            align-self: center;
            margin: 10px 0 30px 0;

            font-family: "Bai Jamjuree";
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.7px;
            color: #ffffff;
          }
        }

        .item-activity-content-show {
          height: 135px;
          transition: all ease-in 0.3s;
        }
        .item-activity-content-show::-webkit-scrollbar,
        .item-activity-content::-webkit-scrollbar {
          display: none;
        }

        .item-activity-content-show {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      }
    }

    .item-description-chest {
      max-width: 1069px;
    }

    .item-features {
      background: #0f0c0899 0% 0% no-repeat padding-box;
      border: 1px solid #6b6152;
      border-radius: 6px;
      width: 100%;
      max-width: 585px;
      padding: 20px 35px;
      display: flex;
      align-items: center;

      ul {
        margin: 0;
        padding: 0 0 0 0px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        li {
          list-style: none;
          display: flex;

          .circle {
            max-width: 10px;
            min-width: 10px;
            width: 27px !important;
            height: 9px;
            background-color: #38fea8;
            border-radius: 100%;
            margin: 5px 10px 0 0;
          }

          .text {
            font-family: "Bai Jamjuree";
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }
    }

    .parents-info {
      background: #0f0c0899 0% 0% no-repeat padding-box;
      border: 1px solid #6b6152;
      border-radius: 8px;
      width: 100%;
      max-width: 585px;
      display: flex;
      flex-direction: column;

      .parents-info-header {
        height: 43px;
        width: 100%;
        background: #ffdd8b4d 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
        padding: 0 20px;

        p {
          font-family: "Bai Jamjuree";
          font-weight: bold;
          font-size: 20px;
          line-height: 40px;
          letter-spacing: 1px;
          color: #ffdd8b;
          text-transform: uppercase;
        }
      }

      .parents-container {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin: 40px 0 0 0;

        .mother,
        .father {
          background: #30291b 0% 0% no-repeat padding-box;
          border: 1px solid #77694b;
          border-radius: 12px;
          min-width: 239px;
          min-height: 204px;
          padding: 0 10px;
          display: flex;
          flex-direction: column;

          div {
            display: flex;
            gap: 10px;
          }

          div > span {
            font-family: "Bai Jamjuree";
            font-size: 14px;
            line-height: 40px;
            letter-spacing: 0.7px;
            text-transform: uppercase;
          }

          .fheral-img {
            width: 100%;
            max-width: 211px;
            height: auto;
          }

          p {
            margin: 0;
            font-family: "Bai Jamjuree";
            font-size: 14px;
            line-height: 40px;
            letter-spacing: 0.7px;
            color: #ffffff;
            text-transform: uppercase;
            align-self: center;
          }
        }

        .father > div > span {
          color: #7ee9ff;
        }

        .mother > div > span {
          color: #ffa5c7;
        }
      }

      .breed-info {
        background: #251f17 0% 0% no-repeat padding-box;
        border: 1px solid #77694b;
        border-radius: 8px;
        min-width: 502px;
        min-height: 120px;
        align-self: center;
        margin: 30px 0 50px 0;
        display: flex;
        flex-direction: column;

        .breed-info-header {
          background: #ffdd8b4d 0% 0% no-repeat padding-box;
          border-radius: 8px 8px 0px 0px;
          height: 39px;

          p {
            font-family: "Bai Jamjuree";
            font-weight: bold;
            font-size: 16px;
            line-height: 40px;
            letter-spacing: 0.8px;
            color: #ffdd8b;
            text-transform: uppercase;
            padding: 0 20px;
          }
        }

        span {
          font-family: "Bai Jamjuree";
          font-size: 14px;
          line-height: 40px;
          letter-spacing: 1.4px;
          color: #ffffff;
          text-transform: uppercase;
          padding: 15px 0 0 30px;
        }
      }
    }
  }
}

.marketplace-item-info-container-chest {
  max-width: 1674px;
}

@media (max-width: 1390px) {
  .marketplace-item-info-container-chest {
    .top {
      .image-container-chest {
        .item-image-container-chest {
          .item-image-chest {
            width: 25rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1282px) {
  .marketplace-item-info-container {
    .bottom {
      .item-description {
        .description-content {
          padding: 20px 0 20px 0;
        }
      }
    }
  }
}

@media (max-width: 1270px) {
  .marketplace-item-info-container-chest {
    .top {
      .image-container-chest {
        .item-image-container-chest {
          .item-image-chest {
            width: 20rem;
            margin: 220px 0 0 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1190px) {
  .marketplace-item-info-container {
    .bottom {
      .item-description-container {
        .item-description {
          .description-header {
            .header-attributes {
              margin: 0 0 0 70px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1134px) {
  .marketplace-item-info-container {
    .top {
      .item-info {
        padding: 30px 20px;
      }
    }
  }
}

@media (max-width: 1080px) {
  .marketplace-item-info-container {
    .top {
      .item-info {
        max-width: 415px;
      }
    }

    .bottom {
      .item-description {
        .description-header {
          .header-attributes {
            display: none;
          }
        }

        .description-content {
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .attributes-header-mobile {
            display: flex !important;
          }

          .description-text {
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .marketplace-item-info-container {
    .bottom {
      .parents-info {
        .parents-container {
          margin: 40px 10px 0 10px;
          .father,
          .mother {
            min-width: 0;
            min-height: fit-content;
          }
        }

        .breed-info {
          min-width: 95%;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .marketplace-item-info-container {
    .bottom {
      .item-description-container {
        .item-description {
          .skill {
            flex-direction: column;

            .skills-content {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 973px) {
  .marketplace-item-info-container {
    .top {
      .image-container-chest {
        .item-image-container-chest {
          .item-image-chest {
            height: auto !important;
            margin: 75px 0 0 0;
          }
        }
      }

      .image-container {
        .item-image-container {
          align-content: center;
          .item-image {
            height: 500px;
          }
        }
      }
    }
  }
}

@media (max-width: 830px) {
  .marketplace-item-info-container {
    .top {
      .image-container {
        .item-image-container {
          align-content: center;
          .item-image {
            height: 450px;
          }

          .item-title {
            bottom: 85px;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .marketplace-item-info-container {
    .top,
    .bottom {
      flex-direction: column;
    }

    .top {
      .item-info {
        max-width: 100%;

        h1 {
          text-align: center;
        }

        .current-price {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .buttons-container {
          margin: 20px 0 0 0;
        }

        .owned {
          position: relative;
          text-align: center;
          margin: 20px 0 0 0;
          bottom: auto;
          left: auto;
        }
      }
    }

    .bottom {
      .item-features {
        min-width: 100%;
      }

      .parents-info {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .marketplace-item-info-container {
    .top {
      .image-container {
        .item-image-container {
          align-content: center;
          .item-image {
            height: 372px;
          }

          .item-title {
            bottom: 110px;
            p {
              font-size: 18px;
              max-width: 200px;
            }
          }
        }
      }
    }

    .bottom {
      .parents-info {
        .parents-container {
          display: flex;
          flex-direction: column;

          .father,
          .mother {
            display: flex;
            align-items: center;
          }

          .father > div,
          .mother > div {
            align-self: flex-start;
          }
        }
      }
    }
  }
}
